export default {
  'Product category name': 'Nombre del producto',
  'Category Name': 'Nombre de la categoría',
  'Please enter the first name': 'Por favor ingrese el nombre',
  'Please enter the last name': 'Por favor ingrese el apellido',
  'Please enter the address': 'Por favor ingrese la dirección',
  'Please enter the country': 'Por favor ingresa el país',
  'Please enter the province': 'Por favor ingresa la provincia',
  'Please enter the city': 'Por favor ingresa la ciudad',
  'Please enter the phone': 'Por favor ingrese el número de teléfono',
  'Please enter the postcode': 'Por favor ingrese el código postal',
  'Total Payment': 'pago total',
  'Total Merchandise': 'Pago total',
  'SKU Details': 'detalles del sku',
  'Commodity Price': 'Detalles del SKU',
  'Set as default shipping address': 'Precio de los bienes',
  'Reset': 'Establecer como dirección de envío predeterminada',
  'Confirm': 'Reiniciar',
  'Subscribe to our newsletter': 'Confirmar',
  'Promotions, new products and sales. Directly to your inbox.': 'Suscríbete a nuestro boletín',
  'Your email': 'Promociones, nuevos productos y ventas. Directamente a tu bandeja de entrada.',
  'Subscribe': 'Su e-mail',
  'Company Information': 'Inscribirse',
  'OUR SOCIAL MEDIA': 'Información de la empresa',
  'PRIVACY STATEMENT': 'NUESTRAS REDES SOCIALES',
  'Terms and Conditions': 'DECLARACION DE PRIVACIDAD',
  'Sure': 'Términos y Condiciones',
  'My Profile': 'mi perfil',
  'Login/Register': 'Iniciar sesión/Registrarse',
  'Logout': 'Salir',
  'Warning Tips': 'Consejos de advertencia',
  'Warning Tips': 'Consejos de advertencia',
  'There are currently no products under this category': 'Actualmente no hay productos en esta categoría',
  'Maximum recommended size 200px * 200px, image can be adaptive': 'Tamaño máximo recomendado 200px * 200px, la imagen puede ser adaptable',
  'add image': 'Añadir imagen',
  'change': 'Cambiar',
  'delete': 'Borrar',
  'Edit Product': 'Editar producto',
  'Please contact the supplier to improve the external number of the webmaster': 'Póngase en contacto con el proveedor para mejorar el número de webmaster externo.',
  'Back': 'para volver',
  'Please contact the supplier to improve the external number of the webmaster': 'Póngase en contacto con el proveedor para mejorar el número de webmaster externo.',
  'Black': 'Negro',
  'There are currently no products under this category': 'Actualmente no hay productos en esta categoría',
  'Logo': 'Logo',
  'You can find the recent quotationsand check offers': 'Podrás encontrar cotizaciones recientes y consultar ofertas.',
  'Maximum recommended size 200px * 200px, image can be adapted': 'Tamaño máximo recomendado 200px * 200px, la imagen se puede adaptar',
  'Add image': 'Añadir imagen',
  'Change': 'Cambiar',
  'Delete': 'Borrar',
  'Change image': 'Cambiar imagen',
  'Shopify tags': 'Etiquetas de Shopify',
  'New Tag': 'Nueva etiqueta',
  'Shopify type': 'Me gusta Shopify',
  'Loading': 'Cargando',
  'Compare Price': 'Comparar el precio',
  'Cost Price': 'Precio justo',
  'Product Cost': 'Costo del producto',
  'Cancel': 'Cancelar',
  'incorrect input': 'Entrada incorrecta',
  'Tag name': 'Nombre de etiqueta',
  'Distributor commission withdrawal': 'Retiro de la Comisión del Distribuidor',
  'Withdrawal method': 'método de retiro',
  'Withdrawal Amount': 'Cantidad retirada',
  'Please enter the commission withdrawal amount': 'Por favor ingrese el monto del retiro de la comisión',
  'Remark': 'Observación',
  'Please enter content': 'Por favor ingresa el contenido',
  'Save': 'Ahorrar',
  'Close': 'Cerrar',
  'balance': 'balance',
  'offline': 'desconectado',
  'Withdrawal list': 'Lista de retiro',
  'accountPayee': 'Beneficiario de la cuenta',
  'Withdrawal method': 'Método de retiro',
  'Approval Status': 'Estado de aprobación',
  'Withdrawal Amount': 'Cantidad de retiro',
  'reject': 'Rechazar',
  'Customer Name': 'Nombre del cliente',
  'agree': 'Llegar a un acuerdo',
  'please enter': 'Por favor inserte',
  'application time': 'Tiempo de aplicación',
  'start': 'Comenzar',
  'end': 'Fin',
  'to': 'Para',
  'Selected stores': 'Tiendas seleccionadas',
  'Please select the Shopify stores': 'Por favor seleccione tiendas Shopify',
  'Shopify': 'comprar',
  'Woocommerce': 'WooCommerce',
  'Lazada': 'Lazada',
  'Shopee': 'tienda',
  'Action': 'Acción',
  'Shopify store': 'Tienda Shopify',
  'Change All': 'Cambia todo',
  'Multiply Price': 'Multiplicar precio',
  'Add Price': 'Añadir precio',
  'Wayfair': 'camino justo',
  'Open in Web': 'Abierta en el navegador',
  'Your price': 'Tu precio',
  'Push to Store': 'Enviar a la tienda',
  'Add Cost Range': 'Agregar rango de costos',
  'Rest of the price ranges': 'Resto de rangos de precios',
  'Price': 'Precio',
  'Plus': 'Más',
  'Failed reason': 'Razón del fracaso',
  'Multiply': 'Multiplicar',
  'Fixed Price': 'Precio fijo',
  'You can set a specific cent value for your price or comparison': 'Puede establecer un valor de centavos específico para su precio o precio de comparación.',
  'price.': 'Asignar centavos',
  'Assign Cent': 'Asignar comparación en centavos',
  'Assign compare at Cent': 'Asignar comparación en centavos',
  'According to store currency': 'Según la moneda de la tienda.',
  'Apply for products in Import List': 'Aplicar a productos en la Lista de Importación',
  'Add New Tags': 'Agregar nuevas etiquetas',
  'Are you sure to delete it??': '¿Estás seguro de que quieres eliminarlo?',
  'Please enter the New tag': 'Por favor inserte una nueva etiqueta',
  'Are you sure you want to push?': '¿Estás seguro de que quieres enviar?',
  'Do you want to delete this task? Tips': '¿Quieres eliminar esta tarea? Consejos',
  'success': 'éxito',
  'error': 'error',
  'The update has started, it will take a while to finish.': 'La actualización ha comenzado, tardará un poco en finalizar.',
  'save successfully': 'Guardado exitosamente',
  'Do you want these?': '¿Quieres estos?',
  'product(s)?': 'productos)?',
  'Delete success': 'Eliminación exitosa',
  'Would you like to export ?': '¿Quieres exportar?',
  'Export success': 'Exportación exitosa',
  'Please select the products': 'Por favor seleccione productos',
  'URL required': 'URL requerida',
  'Unit Cost': 'Costo unitario',
  'Import success': 'Importación exitosa',
  'Please select the tags': 'Por favor seleccione etiquetas',
  'Tags added successfully': 'Etiquetas agregadas exitosamente',
  'You can import and manage your AliExpress / Amazon products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products.': 'Puedes importar y administrar tus productos de AliExpress/Amazon aquí antes de publicarlos en tu tienda Shopify. Puede publicar un producto en diferentes tiendas y agregar varias etiquetas a cada producto.',
  'Import Aliexpress / Amazon product URL here and click': 'Importe la URL del producto Aliexpress/Amazon aquí y haga clic',
  'Product Name': 'Nombre del producto',
  'Product SKU': 'SKU del producto',
  'Shipping to': 'Enviar a',
  'Shipping fee': 'Gastos de envío',
  'Add to List': 'Agregar a la lista',
  'VAT information': 'información del IVA',
  'Not purchased': 'No comprado',
  'you can click': 'puedes hacer clic',
  'for VAT information': 'para información sobre el IVA',
  'Cost per unit': 'Costo por unidad',
  'Clear selection': 'Selección clara',
  'Total Quantity selected': 'Cantidad total seleccionada',
  'Total Quantity': 'Cantidad total',
  'You can find the recent quotations and check offers': 'Podrás encontrar cotizaciones recientes y consultar ofertas.',
  'Waiting for vendors respond': 'Esperando respuesta de proveedores',
  'Vendors responding quotations': 'Cotizaciones de proveedores que respondieron',
  'Archived quotations': 'Cotizaciones archivadas',
  'Fulfilled': 'Atendido',
  'Unfulfilled': 'No se cumplen',
  'Partially Fulfilled': 'Cumplido parcialmente',
  'Refunded': 'Reintegrado',
  'Today': 'Hoy',
  'Last 7 days': 'Los últimos 7 días',
  'Last 30 days': 'últimos 30 días',
  'Last 90 days': 'últimos 90 días',
  'Last 12 months': 'últimos 12 meses',
  'Custom': 'Costumbre',
  'Order number (ascending)': 'Número de pedido (ascendente)',
  'Order number (descending)': 'Número de pedido (descendente)',
  'Order number': 'Numéro del pedido',
  'Date (oldest first)': 'Fecha (la más antigua primero)',
  'Date (newest first)': 'Fecha (la más reciente primero)',
  'Partially refunded': 'Reintegrado parcialmente',
  'orders selected': 'pedidos seleccionados',
  'orders selected, uncheck': 'pedidos seleccionados, desmarque',
  'orders selected, select all': 'pedidos seleccionados, seleccionar todo',
  'Request Quotation': 'Solicitud de cotización',
  'orders': 'peticiones',
  'Only orders in': 'Sólo pedidos en',
  'Paid': 'Pagado',
  'No order selected': 'No hay pedidos seleccionados',
  'Create quotation request': 'Crear solicitud de cotización',
  'can': 'él puede',
  'Has been copied to the clipboard': 'Fue copiado al portapapeles.',
  'Copy to clipboard failed, please copy manually': 'No se pudo copiar al portapapeles, cópielo manualmente',
  'waiting for responding': 'esperando respuesta',
  'Cancelled': 'Cancelado',
  'Confirmed': 'Confirmado',
  'ID Range': 'rango de identificación',
  'Order Includes': 'El pedido incluye',
  'View offers': 'Ver ofertas',
  'Cancel offers': 'Cancelar ofertas',
  'Check offers': 'Consultar ofertas',
  'View offer detail': 'Ver detalles de la oferta',
  'Fulfillment': 'Servicio',
  'Order': 'Orden',
  'My Store': 'Mi tienda',
  'Date': 'Fecha',
  'Customer': 'Cliente',
  'Total': 'Total',
  'Payment': 'Pago',
  'Allocate all to vendor': 'Asignar todo al proveedor.',
  'Fulfillment': 'Servicio',
  'Allocate': 'Ubicar',
  'button of the page,you can also allocate items from the same SPU to the same vendor': 'botón de página, también puede asignar artículos de la misma SPU al mismo proveedor',
  'Items': 'ITENS',
  'Product images': 'Imágenes del producto',
  'Details': 'Detalles',
  'Expected price (US$)': 'Precio esperado (EE.UU.)',
  'Remark': 'Observación',
  'Vendors respond': 'Los proveedores responden',
  'Status': 'Estado',
  'Purchase Order Reconfirm': 'Reconfirmar orden de compra',
  'Vendor name:': 'Nombre del proveedor:',
  'AilExpress shop：': 'Tienda AilExpress:',
  'Invite Vendor': 'Invitar proveedor',
  'Invite Link:': 'Enlace de invitación:',
  'Bonus Detail': 'Detalles de bonificación',
  'Amount($)': 'Valor ()',
  'Product name': 'Nombre del producto',
  'Order Quantity': 'ordene la cantidad',
  'Commission': 'Comisión',
  'Enter order number': 'Introduzca el número de pedido',
  'Product information': 'Información del Producto',
  'Total commission': 'Comisión total',
  'Edit Product': 'Editar producto',
  'Shopify vendor': 'Proveedor de Shopify',
  'What is your store name?': '¿Cómo se llama tu tienda?',
  'What is your Email?': '¿Cuál es tu email?',
  'Cancel purchase order': 'Cancelar orden de compra',
  'Use this button to update your Order List if missing orders.The process may take a while to finish.': 'Utilice este botón para actualizar su Lista de pedidos si falta algún pedido. El proceso puede tardar algún tiempo en completarse.',
  'Offered Orders': 'Pedidos ofrecidos',
  'Offered Products': 'Productos ofrecidos',
  'Offer price': 'Precio de oferta',
  'View offer detail': 'Ver detalles de la oferta',
  'Discard': 'Descartar',
  'Hold down the slider and drag to the right': 'Mantenga presionado el control deslizante y arrastre hacia la derecha',
  'In order to keep you informed of the update of the order, we suggest you verify the email': 'Para mantenerte informado sobre actualizaciones de pedidos, te sugerimos revisar tu correo electrónico',
  'orders can be process, what do you want to do now?': 'Los pedidos se pueden procesar, ¿qué quieres hacer ahora?',
  'Need new quotation for my orders': 'Necesito una nueva cotización para mis pedidos.',
  'Over 30000+ vendors waiting for offer you price': 'Más de 30000 proveedores esperando para ofrecerte un precio',
  'Allocate my exist vendors': 'Asignar a mis proveedores existentes',
  'Just check my orders': 'Solo revisa mis pedidos',
  'If you already have cooperative vendors, you can allocate orders to them': 'Si ya tiene proveedores cooperativos, puede asignarles pedidos.',
  'How to process your orders?': '¿Cómo procesar tus pedidos?',
  'Please select at least one order and quote for your order': 'Seleccione al menos un pedido y solicite una cotización para su pedido',
  'Confirm this Offer': 'Confirmar esta oferta',
  'All Orders': 'Todas las órdenes',
  'All Products': 'Todos los productos',
  'Add Tracking': 'Agregar seguimiento',
  'Yes, I want to CANCEL this offer': 'Sí, quiero CANCELAR esta oferta',
  'Cancel the success': 'Cancelar con éxito',
  'Track number': 'Número de rastreo',
  'Please input the tracking number': 'Por favor ingrese el número de seguimiento',
  'Track company': 'Empresa de seguimiento',
  'Please select logistics company': 'Por favor seleccione la empresa de logística',
  'Your request has been sent': 'Tu solicitud ha sido enviada',
  'The receiving address has no logistics method set': 'La dirección de entrega no tiene un método logístico definido',
  'The order is below the minimum deliverable weight': 'El pedido está por debajo del peso mínimo para la entrega.',
  'The order is below the minimum deliverable volume': 'El pedido está por debajo del volumen mínimo para la entrega.',
  'The order is higher than the maximum deliverable weight': 'El pedido supera el peso máximo para la entrega.',
  'The order is higher than the maximum deliverable volume': 'El pedido supera el volumen máximo de entrega.',
  'The following product attributes of this order do not meet the attribute requirements of deliverable products': 'Los siguientes atributos de producto de este pedido no cumplen con los requisitos de productos que se pueden enviar',
  'The total weight of the goods in this order does not meet the deliverable requirements': 'El peso total de los productos de este pedido no cumple con los requisitos de entrega.',
  'Volume(cm)': 'Volumen (cm)',
  'Total volume(cm)': 'Volumen total (cm)',
  'Attribute': 'Atributo',
  'I Know': 'Yo se',
  'Total weight': 'Peso total',
  'Order Information': 'información del pedido',
  'Third party order number': 'Número de pedido de terceros',
  'Order Number': 'Numéro del pedido',
  'Stores': 'Historias',
  'Creation time': 'Fecha de creación',
  'Kaieteur Bank Payment': 'Pago del banco Kaieteur',
  'Waiting for the payment result to be returned.': 'Esperando que se devuelva el resultado del pago.',
  'You may need to wait a little while': 'Quizás tengas que esperar un poco',
  'View order': 'Ver pedido',
  'You have successfully completed your order.': 'Ha completado con éxito su pedido.',
  'Sorry, we can': 'Lo siento, no podemos',
  'Pay again': 'pagar de nuevo',
  'Order No.': 'Numéro del pedido',
  'Receiving': 'Recibo',
  'days': 'días',
  'Shipping days': 'Días de envío',
  'Subtotal': 'Total parcial',
  'Freight': 'Flete',
  'Discount': 'Descuento',
  'Exchange': 'Intercambio',
  'Credits': 'Créditos',
  'The supplier has opened a credit limit for you. When your account balance is insufficient, you can deduct the credit limit': 'El proveedor le ha abierto un límite de crédito. Cuando el saldo de su cuenta es insuficiente, puede deducir su límite de crédito.',
  'Payment method': 'Método de pago',
  'There is no alternative method of payment': 'No existe ningún método de pago alternativo.',
  'Quantity of order': 'Ordene la cantidad',
  'Total amount of goods': 'Valor total de los productos',
  'Tax Amount': 'Importe del impuesto',
  'Discounted price of goods': 'Precio con descuento de productos.',
  'Total freight amount': 'Costo total de envío',
  'Shop discount': 'Descuento en tienda',
  'Amount due (including freight)': 'Importe adeudado (incluido el envío)',
  'This document is free of freight': 'Este documento no tiene envío',
  'Submit orders': 'Enviar pedidos',
  'credit&debit card': 'tarjeta de crédito y débito',
  'qr payment': 'pago por código QR',
  'Please select payment method': 'Por favor seleccione el método de pago',
  'Order Management': 'Gestión de pedidos',
  'Import order': 'Orden de importación',
  'Your No.': 'Tu no.',
  'Export order': 'Orden de exportación',
  'Filter': 'Filtrar',
  'Clear': 'Limpiar',
  'Create a quote': 'Crear una cotización',
  'Quit quotation': 'Cancelar cotización',
  'Bulk payment': 'El pago a granel',
  'Cancel payment': 'Cancelar el pago',
  'Manually synced': 'Sincronizado manualmente',
  'Continue Pay': 'Continuar pagando',
  'Re quote': 'Rehacer cotización',
  'Your shop order num': 'El número de pedido de tu tienda',
  'Unable to purchase': 'No se puede comprar',
  'Products Off shelf': 'Productos agotados',
  'Supply unit price': 'Precio unitario de oferta',
  'Shop price：': 'Precio en tienda:',
  'customer Name': 'Nombre del cliente',
  'Not reviewed': 'No revisado',
  'pass': 'Aprobar',
  'reject': 'Rechazar',
  'Please make an offer': 'Por favor haz una oferta',
  'Removed': 'Remoto',
  'Delete': 'Borrar',
  'Order Number': 'Numéro del pedido',
  'Unable to purchase': 'No se puede comprar',
  'Please make an offer': 'null Por favor haz una oferta',
  'warehouse': 'almacenamiento',
  'address': 'DIRECCIÓN',
  'Customer': 'Cliente',
  'Products': 'Productos',
  'Product': 'Producto',
  'Shipping Method': 'Método de envío',
  'Manually synced': 'Sincronizado manualmente',
  'Payment time': 'tiempo de pago',
  'Payment amount': 'Monto del pago',
  'inventory': 'inventario',
  'Inventory lock remaining time': 'Tiempo restante de bloqueo del inventario',
  'Order quantity': 'Ordene la cantidad',
  'Cancel payment': 'Cancelar el pago',
  'Unable to purchase': 'No se puede comprar',
  'Please make an offer': 'null Por favor haz una oferta',
  'Please make an offer': 'Por favor haz una oferta',
  'Created Time': 'Tiempo de creación',
  'Shipping address': 'Dirección de envío',
  'Request Fullfill': 'Solicitar servicio completo',
  'Shipping Method': 'Método de envío',
  'Shipping cost': 'Costo de envío',
  'pay total': 'pagar en completo',
  'Select Payment Method': 'Seleccione método de pago',
  'All transactions are secure and encrypted': 'Todas las transacciones son seguras y encriptadas.',
  'Pay with': 'Pagar con',
  'Please do not close the page during payment': 'Por favor no cierre la página durante el pago.',
  'Your order has been successfully paid': 'Su pedido ha sido pagado con éxito',
  'The selected orders are abnormal and payment cannot be continued': 'Los pedidos seleccionados son anormales y no se puede continuar con el pago.',
  'Payment can continue after excluding the following goods': 'El pago puede continuar después de eliminar los siguientes productos',
  'After removing the off shelf goods, the order will be returned to the': 'Después de retirar los productos agotados, el pedido será devuelto al',
  'price to be quoted': 'precio a cotizar',
  'Confirm': 'Confirmar',
  'Select file': 'Seleccione Archivo',
  'Click to download': 'Haga clic para descargar',
  'Order Import Template': 'Plantilla de importación de pedidos',
  'Please fill in the form according to the specification, only xlsx format files are supported': 'Complete el formulario según las especificaciones, solo se admiten archivos en formato xlsx',
  'The uploaded file cannot exceed 2M, and only the first 99 data are processed': 'El archivo cargado no puede exceder los 2 M y solo se procesan los primeros 99 datos.',
  'submit': 'entregar',
  'Quotation to be submitted': 'Cotización a presentar',
  'Vendor quoted order': 'Pedido cotizado por proveedor',
  'Payment Not Received': 'Pago no recibido',
  'Pending payment': 'Pago pendiente',
  'Waiting for fulfill': 'Esperar a cumplir',
  'Fulfilled': 'Cumplido',
  'Completed': 'Concluido',
  'Logistics synchronization failed': 'Fallo de sincronización logística',
  'Unable to purchase': 'No se puede comprar',
  'Payment to be completed': 'Pago por completar',
  'Waiting for fulfill': 'Esperar a cumplir',
  'Quit quotation': 'Salir de la cotización',
  'danger': 'peligro',
  'Delivery Address': 'Dirección de entrega',
  'My stores': 'Mis tiendas',
  'warning': 'Aviso',
  'Vendor Verifying Payment': 'Proveedor verificando el pago',
  'Payment result to be returned': 'Resultado del pago a devolver',
  'Sure to delete this item?': '¿Estás seguro de eliminar este elemento?',
  'OK': 'DE ACUERDO',
  'cancel': 'Cancelar',
  'Delete Successful!': '¡Eliminación exitosa!',
  'Canceled': 'Cancelado',
  'Please select an order': 'Por favor seleccione un pedido',
  'Binding succeeded!': '¡La unión lo logró!',
  'There is no warehouse, please go to add！': 'No hay almacén, por favor vaya a agregar',
  'Please choose file': 'Por favor elige el archivo',
  'file cannot over 2MB': 'el archivo no puede exceder los 2MB',
  'Create Success': 'Crear éxito',
  'No goods need to be eliminated': 'No es necesario eliminar ninguna mercancía.',
  'Please select an order firs': 'Por favor seleccione un primer pedido',
  'Successfully': 'Con éxito',
  'No logistics': 'Sin logística',
  'Are you sure to abandon payment for the selected order? After abandoning, the order will disappear from the list, which cannot be restored, please operate with caution': '¿Está seguro de abandonar el pago del pedido seleccionado? Después de abandonar, el pedido desaparecerá de la lista y no se podrá restaurar. Opere con precaución.',
  'Are you sure to initiate a quotation for the selected order?': '¿Está seguro de iniciar una cotización para el pedido seleccionado?',
  'Are you sure to abandon the quotation for the selected order? After giving up, it cannot be restored, please operate with caution': '¿Está seguro de abandonar el presupuesto del pedido seleccionado? Después de darse por vencido, no se puede restaurar, opere con precaución',
  'Payment has not arrived': 'El pago no ha llegado',
  'Payment to be completed': 'Pago por completar',
  'Waiting for fulfillment': 'Esperar a que se cumpla',
  'Logistics synchronization failed': 'Fallo de sincronización logística',
  'Unable to purchase': 'No se puede comprar',
  'Order ID': 'Solicitar ID',
  'SortBy': 'Triste',
  'Date Ranges': 'Rangos de fechas',
  'Fulfillment Status': 'Estado de cumplimiento',
  'Export to': 'Exportar a',
  'Selected': 'Seleccionado',
  'Some orders had been fulfilled or refunded': 'Algunos pedidos fueron cumplidos o reembolsados.',
  'Export orders': 'Pedidos de exportación',
  'Attribute Value': 'Valor de atributo',
  'Abnormal reason': 'Razón anormal',
  'Date type': 'Tipo de fecha',
  'Download tracking information': 'Descargar información de seguimiento',
  'Your Orders are exporting</strong><br/>Your export will be delivered by email to': 'Tus pedidos se están exportando</strong><br/>Tu exportación se entregará por correo electrónico a',
  'Depending on how many Orders you’re exporting, this could take some time.': 'Dependiendo de cuántos pedidos esté exportando, esto puede llevar algún tiempo.',
  '<strong>Your Orders are exporting</strong><br/>Your export will be delivered by email to: . <br/>Depending on how many Orders you’re exporting, this could take some time.': '<strong>Sus pedidos se están exportando</strong><br/>Su exportación se entregará por correo electrónico a: . <br/>Dependiendo de cuántos pedidos esté exportando, esto podría llevar a',
  'Notice': 'Aviso',
  'You have': 'Tú tienes',
  'purchase order(s) need your reconfirm,': 'la(s) orden(es) de compra necesita su reconfirmación,',
  'click here': 'Haga clic aquí',
  'to view details.': 'para ver detalles.',
  'Vendor shipped': 'Proveedor enviado',
  'unfulfilled / partially fulfilled)': 'no cumplido/cumplido parcialmente)',
  'But': 'Pero',
  'You may download these tracking information and fulfilled these orders in your Shopify store manualy.': 'Puedes descargar esta información de seguimiento y completar estos pedidos manualmente desde tu tienda Shopify.',
  'Download ALL tracking information': 'Descargue TODA la información de seguimiento',
  'sync failed': 'error de sincronización',
  'Reconfirm': 'Reconfirmar',
  'View Details': 'Ver detalles',
  'Change another vendor': 'Cambiar proveedor',
  'Export': 'Exportar',
  'Cancel this order': 'Cancelar este pedido',
  'NOT Joined': 'NO ADHERIDO',
  'Amount Due': 'Monto adeudado',
  'Not set up': 'No configurado',
  'View Details': 'Ver detalles',
  'Send to vendor': 'Enviar al proveedor',
  'Share by link': 'Compartir mediante enlace',
  'Share link': 'Compartir enlace',
  'Copy Link': 'Copiar link',
  'Title': 'Título',
  'Desc': 'Descripción',
  'Share': 'Compartir',
  'Scan share to WeChat': 'Escanear Compartir en WeChat',
  'Includes': 'Incluye',
  'Shopify orders': 'Pedidos de Shopify',
  'products': 'productos',
  'invalid': 'inválido',
  'Income': 'Actuación',
  'Income will only visible to you': 'Los ingresos sólo serán visibles para ti',
  'Allow vendor to process these orders': 'Permitir que el proveedor procese estos pedidos.',
  'Do you want to cancel this order?': '¿Quieres cancelar este pedido?',
  'Don': 'Don',
  'Shopify orders in the': 'Pedidos de Shopify en',
  'Discard': 'Desecho',
  'Change purchase order to another vendor': 'Cambiar orden de compra a otro proveedor',
  'Current vendor:': 'Proveedor actual:',
  'Change to new vendor': 'Cambiar a un nuevo proveedor',
  'All my vendors': 'Todos mis proveedores',
  'Also allocate the products in this purchase order to the new supplier': 'Asigne también los productos de esta orden de compra al nuevo proveedor.',
  'Order created': 'Orden creada',
  'Order paid': 'Orden pagada',
  'Login to your': 'Inicie sesión en su',
  'Order confirmed': 'Orden confirmada',
  'Your request is been submitted, please wait one moment.': 'Su pedido ha sido enviado, espere un momento.',
  'Copy to clipboard failed, please copy manually': 'Error al copiar al portapapeles, cópielo manualmente',
  'The update process has started, it will take a while to finish.': 'El proceso de actualización ha comenzado, tardará un poco en finalizar.',
  'Update Order': 'Orden de actualización',
  'You have': 'Tú tienes',
  'purchase orders': 'ordenes de compra',
  'NEED to finish payment': 'NECESITA TERMINAR EL PAGO',
  'Waiting for allocated': 'Esperar a que se le asigne',
  'Waiting for sourcing': 'Esperando suministro',
  'Orders processing': 'Procesando orden',
  'Abnormal order': 'orden anormal',
  'Abnormal': 'No es normal',
  'All orders': 'Todas las órdenes',
  'Pending': 'Pendiente',
  'Please select': 'Por favor seleccione',
  'Quote for Orders': 'Cotización para pedidos',
  'Quote for Product': 'Cotización del producto',
  'Recent Quotations': 'Cotizaciones recientes',
  'orders matching your search': 'pedidos que coinciden con su búsqueda',
  'Export order': 'Orden de exportación',
  'Email': 'Correo electrónico',
  'Change': 'Cambiar',
  'Get captcha': 'Obtener captcha',
  'try again in': 'inténtalo de nuevo en',
  'seconds': 'segundos',
  'Please fill in the correct Email address': 'Por favor complete la dirección de correo electrónico correcta',
  'User Email modified successfully': 'Correo electrónico del usuario modificado exitosamente',
  'Your Orders are exporting</strong><br/>Your export will be delivered by email to': 'Tus pedidos se están exportando</strong><br/>Tu exportación se entregará por correo electrónico a',
  '<br/>Depending on how many Orders you’re exporting, this could take some time': '<br/>Dependiendo de cuántos pedidos esté exportando, esto puede llevar algún tiempo',
  'Show cancelled orders': 'Mostrar pedidos cancelados',
  'Show all valid orders': 'Mostrar todos los pedidos válidos',
  'unfulfilled / partially fulfilled': 'no cumplido/cumplido parcialmente',
  'Download NOT FULFILLED tracking information': 'Información de seguimiento NO CUMPLIDA',
  'sync failed': 'error de sincronización',
  'Change another vendor': 'Cambiar proveedor',
  'Invite vendor to process': 'Invitar al proveedor a procesar',
  'More Action': 'Más acción',
  'Selected saved! Now you can Check the purchase orders': 'Seleccionado guardado! Ahora puedes consultar órdenes de compra',
  'After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up': 'Después de la confirmación del pago, notificaremos al proveedor por correo electrónico. También puede notificar proactivamente al proveedor para acelerar la entrega.',
  'Have you finished the payment?': '¿Has terminado el pago?',
  'Confirm the payment': 'Confirmar pago',
  'The reason must be entered': 'Se debe ingresar el motivo',
  'In this tab, we will only filter your': 'En esta pestaña solo filtraremos tu',
  'Orders which you can allocate to your vendor to process.': 'Pedidos que puedes asignar a tu proveedor para que los procese.',
  'Unfulfilled': 'No realizado',
  'Shop name:': 'Nombre de la tienda:',
  'Partially fulfilled': 'Cumplido parcialmente',
  'Fulfillment Status': 'Estado de cumplimiento',
  'Allocate all to vendor': 'Asignar todo al proveedor.',
  'purchased items are delivered': 'los artículos comprados se entregan',
  'Select a vendor': 'Seleccione un proveedor',
  'Product has been allocated': 'El producto ha sido asignado.',
  'Vendor name / contact / shop name etc.': 'Nombre del proveedor/contacto/nombre de la tienda, etc.',
  'Here are some other products seems can been allocated to the vendor too, do you want to do it now?': 'Aquí hay algunos otros productos que parece que también se pueden asignar al proveedor. ¿Quieres hacerlo ahora?',
  'Selected products': 'Productos seleccionados',
  'Allocate NOW': 'Asignar AHORA',
  'No thanks': 'No gracias',
  'Please select at least one order': 'Seleccione al menos un pedido',
  'Create quotation request?': '¿Crear solicitud de cotización?',
  'Copy to clipboard failed, please copy manually': 'Error al copiar al portapapeles, cópielo manualmente',
  'Order ID From:': 'ID de pedido de:',
  'Order ID To:': 'ID de pedido para:',
  'You': 'Tú',
  'orders to': 'pedidos para',
  'vendors': 'vendedores',
  'responded': 'el respondió',
  'You can choose the payment method you can accept, we will submit your': 'Puede elegir el método de pago que puede aceptar,',
  'orders and options to your vendors': 'pedidos y opciones para sus proveedores',
  'Warm tips': 'Consejos cálidos',
  'orders NOW': 'pedidos ahora',
  'Place': 'Lugar',
  'You have set the following products to be allocated to the vendors': 'Ha definido los siguientes productos para asignarlos a proveedores',
  'only once. Therefore, the following items will need to be': 'Apenas una vez. Por lo tanto, los siguientes elementos deberán ser',
  're-allocated after the orders are generated again.': 'reasignados después de que las órdenes se generen nuevamente.',
  'By AliExpress VIP link': 'Por enlace VIP de Aliexpress',
  'Please enter the single number range': 'Por favor ingrese un rango de números único',
  'The starting order number must be less than the ending order number': 'El número de pedido inicial debe ser menor que el número de pedido final.',
  'Product title': 'Titulo del producto',
  'Expected price': 'Precio esperado',
  'Remove entrusted vendor': 'Eliminar proveedor de confianza',
  'Reply price': 'Precio de respuesta',
  'Reply link': 'Enlace de respuesta',
  'published': 'publicado',
  'Yesterday': 'Ayer',
  'Select': 'Seleccionar',
  'My support': 'Mi apoyo',
  'Connect': 'Para conectar',
  'Reauthorize': 'Autorizar nuevamente',
  'Choose a vendor': 'Elige un proveedor',
  'Are you sure you want to remove entrusted vendor?': '¿Está seguro de que desea eliminar al proveedor de confianza?',
  'You have successfully hosted the': 'Has alojado con éxito el',
  'After the successful hosting, your supplier will take the initiative to quote for your pending orders!': '¡Después de un alojamiento exitoso, su proveedor tomará la iniciativa de cotizar sus pedidos pendientes!',
  'Are you sure you want to cancel the hosting store': '¿Estás seguro de que quieres cancelar la tienda de hosting?',
  'after you close the hosting, the supplier will not be able to actively quote your order!': 'Una vez que cierre el hosting, ¡el proveedor no podrá cotizar activamente su pedido!',
  'If you do not check the relevant permissions, it will affect the use of related functions, please operate with caution': 'Si no verifica los permisos relevantes, afectará el uso de funciones relacionadas, opere con precaución',
  'vendor is authorizing the store on BCNDropshipping App, please be noted.': 'El proveedor está autorizando la tienda en la aplicación BCNDropshipping, tenga en cuenta.',
  'Failure reason': 'Motivo del fracaso',
  'Please enter price': 'Por favor ingrese el precio',
  'Store products': 'Tienda de productos',
  'Publish': 'Publicar',
  'Query': 'Consulta',
  'Reset': 'Reanudar',
  'Price units default to': 'Unidades de precio estándar para',
  'Please upload pictures': 'Por favor envía fotos',
  'Please enter title': 'Por favor ingresa el título',
  'Search Products': 'Buscar Productos',
  'Please enter': 'Por favor inserte',
  'State': 'propiedad del estado',
  'Pending': 'Pendiente',
  'Waiting': 'Esperar',
  'Processing': 'Procesando',
  'Failed': 'Rechazado',
  'All': 'Todo',
  'Description:': 'Descripción:',
  'Edit': 'Para editar',
  'ADD': 'AGREGAR',
  'Add Vendor': 'Agregar proveedor',
  'Edit Vendor': 'Editar proveedor',
  'Destination Country:': 'País de destino:',
  'My sourcing products': 'Mis productos de oferta',
  'Vendor information': 'Información del proveedor',
  'Vendor Contact': 'Contacto del proveedor',
  'Bonus Balance（': 'Saldo de bonificación',
  'Sales statistics': 'Estadísticas de ventas',
  'Please enter description': 'Por favor ingrese la descripción',
  'Please enter IOSS ID': 'Por favor ingrese el ID de IOSS',
  'Please select destination country': 'Seleccione el país de destino',
  'Please select at least 1 destination country.': 'Seleccione al menos un país de destino.',
  'Please enter an IOSS ID with 12 alphanumeric characters.': 'Ingrese una ID de IOSS con 12 caracteres alfanuméricos.',
  'After saving, your IOSS will be updated to the corresponding order. Are you sure to save?': 'Después de la grabación, su IOSS se actualizará al orden correspondiente. ¿Estás seguro de que grabarás?',
  'Dashboard': 'Panel',
  'Settings': 'ajustes',
  'According to the new European VAT e-commerce rules, declaration without lOSS will result in a delay in customs clearance and the recipient will be required to pay VAT, as the parcel will be detained when contact of the recipient is incorrect.': 'Según las nuevas normas europeas de comercio electrónico del IVA, la declaración sin pérdida provocará un retraso en el despacho de aduana y el destinatario deberá pagar el IVA, ya que el paquete se retendrá cuando la información de contacto del destinatario sea incorrecta.',
  'IOSS Option': 'Opción IOSS',
  'No IOSS': 'Sin IOSS',
  'The amount of the orders (shipping cost included) will be declared and recipients will be required by the customs to pay VAT when parcels arrive in the destination countries of EU': 'Se declarará el valor de los pedidos (gastos de envío incluidos) y la aduana exigirá a los destinatarios que paguen el IVA cuando los pedidos lleguen a los países de destino de la UE.',
  'Please enter correct and valid IOSS IDs, which should be linked to corresponding destination countries. IOSS ID will not be applied if the destination country is not linked to a correct IOSS ID': 'Ingrese los ID IOSS correctos y válidos, los cuales deben estar vinculados a los países de destino correspondientes. El IOSS ID no se aplicará si el país de destino no está vinculado a un IOSS ID correcto',
  'My IOSS Settings': 'Mi configuración de IOSS',
  'Declare with my own IOSS': 'Declarar con mi propia IOSS',
  'Linked Countries': 'Países vinculados',
  'Add IOSSou sure to delete the IO': 'Agregue IOS o asegúrese de excluir IO',
  'Unlinked Countries:': 'Países no vinculados:',
  'Are you sure to delete the IOSS?': '¿Estás seguro de eliminar IOSS?',
  'More categories': 'Más categorías',
  'Excluding VAT amount': 'Excluido el importe del IVA',
  'Vat Amount': 'valor del IVA',
  'Amount including VAT': 'Importe incluido el IVA',
  'AliExpress store information': 'Información de la tienda Aliexpress',
  'Detailed seller ratings': 'Reseñas detalladas del vendedor',
  'Recent Products': 'Productos recientes',
  'Store No.': 'Almacenar ninguna.',
  'This store has been open since': 'Esta tienda ha estado abierta desde',
  'Positive feedback': 'Retroalimentación positiva',
  'Business License': 'Licencia comercial',
  'Item as Described': 'Artículo según lo descrito',
  'Above': 'Arriba',
  'Below': 'Abajo',
  'Average': 'Promedio',
  'Shipping Speed': 'Velocidad de envío',
  'Detailed Seller Ratings information is unavailable when there': 'La información detallada sobre las calificaciones del vendedor no está disponible cuando',
  'Add vendors success': 'Agregue éxito a los proveedores',
  'Add a new vendor': 'Agregar un nuevo proveedor',
  'My Vendors': 'Mis proveedores',
  'By Vendor Name': 'Por nombre del proveedor',
  'By AliExpress Url': 'Por URL de Aliexpress',
  'Please enter your vendor': 'Por favor ingrese su proveedor',
  'Vendor': 'Vendedor',
  'Vendor': 'Vendedor',
  'How to get the vendor': 'Cómo conseguir el proveedor',
  'Transaction Flow Line': 'Línea de flujo de transacciones',
  'Recharge Time': 'Tiempo de recarga',
  'Are you sure you want to delete it?': '¿Estás seguro de que quieres eliminarlo?',
  'Customer name required': 'Nombre del cliente requerido',
  'customer successfully edited': 'cliente editado exitosamente',
  'customer successfully added': 'cliente agregado exitosamente',
  'Address Management': 'Gestión de direcciones',
  'Add New Address': 'agregar nueva dirección',
  'Default Shipping Address': 'Dirección de entrega por defecto',
  'First name': 'Primer nombre',
  'Last name': 'Apellido',
  'Phone': 'Teléfono',
  'Push': 'Para empujar',
  'Address': 'DIRECCIÓN',
  'City': 'Ciudad',
  'Postcode': 'Código postal',
  'Province': 'Provincia',
  'Country': 'sertón',
  'Edit': 'Para editar',
  'Delete': 'Apagar',
  'No address': 'Sin dirección',
  'Do you want to delete?': '¿Quieres borrar?',
  'delete successfully': 'eliminar exitosamente',
  'My Allocated Products': 'Mis productos asignados',
  'save successfully': 'guardar con éxito',
  'My Allocated Product': 'Mi producto asignado',
  'Batch Re-allocate': 'Reasignación de lotes',
  'Batch Un-allocate': 'Anulación de asignación por lotes',
  'All my stores': 'Todas mis tiendas',
  'UN-allocate Success': 'Éxito en la asignación de la ONU',
  'Home': 'Hogar',
  'My Profile': 'mi perfil',
  'Logout': 'salir',
  'LOGIN': 'ACCESO',
  'Attach image file': 'Adjuntar archivo de imagen',
  'Browse': 'Para navegar',
  'Customer Support Email': 'Correo electrónico de atención al cliente',
  'Send your request': 'Envía tu pedido',
  'Powered by BCN': 'Desarrollado por BCN',
  'Privacy policy': 'Política de privacidad',
  'Terms of use': 'Terminos de uso',
  'Refund Policy': 'Politica de reembolso',
  'Contact Us': 'Contáctenos',
  'Getting Started': 'Primeros pasos',
  'Main Features': 'Principales características',
  'FAQs': 'preguntas frecuentes',
  'This file format is not supported': 'Este formato de archivo no es compatible',
  'The file size can not over 2MB': 'El tamaño del archivo no puede exceder los 2 MB.',
  'Please input your message': 'Por favor ingrese su mensaje',
  'BCNDropshipping - Easy Dropshipping': 'BCNDropshipping: envío directo fácil',
  'Please enter Shopify store name': 'Ingresa el nombre de tu tienda Shopify',
  'The question must not be empty': 'La pregunta no debe estar vacía.',
  'The email must not be empty': 'El correo electrónico no debe estar vacío.',
  'Shopify store name': 'Nombre de la tienda Shopify',
  'Current Unit Cost': 'Costo unitario actual',
  'Send Success,We will contact you soon.': 'Envíe con éxito, nos comunicaremos con usted pronto.',
  'Welcome to': 'Bienvenido al',
  'Please sign in or register an account to start the chat.': 'Inicie sesión o registre una cuenta para comenzar a chatear.',
  'All Items': 'todos los artículos',
  'back': 'atrás',
  'Check All': 'verificar todo',
  'Digital Themrmometer Hygrometer Living Ro Digital Themrmometer Hygrometer Living Ro': 'Higrómetro de alta calidad de Digitaces Themrmometer Ro vivo Higrómetro de Digitaces Themrmometer Ro vivo',
  'Bulk Purchase': 'Compra a granel',
  'Amount': 'Cantidad',
  'Do you want to delete selected items?': '¿Quieres eliminar los elementos seleccionados?',
  'Product list page': 'Página de lista de productos',
  'Bottom': 'Abajo',
  'Top': 'Arriba',
  'Hi, welcome to': 'Hola, bienvenido a',
  'Welcome to ()! Please sign in or register an account to': 'Bienvenido al ()! Por favor inicie sesión o registre una cuenta para',
  ' start the chat.': 'iniciar la charla.',
  'Sign in': 'Hacer login',
  'Register': 'Registro',
  'Recall': 'Recordar',
  'wallet': 'portafolio',
  'balance': 'Balance',
  'system notification': 'notificación del sistema',
  'This month Orders': 'Pedidos de este mes',
  'This month Revenue': 'Receta de este mes',
  'This month Cost': 'Este mes cuesta',
  'Statistics': 'Estadísticas',
  'Reference': 'Referencia',
  'Show More': 'Mostrar más',
  'Quick Summary': 'Sumario rápido',
  'My Products': 'Mis productos',
  'Waiting to place order': 'Esperando para realizar el pedido',
  'Waiting for tracking code': 'Esperando código de seguimiento',
  'Stores': 'Historias',
  'Trade name': 'Nombre comercial',
  'Authorized': 'Autorizado',
  'Purchase price:': 'Precio de compra:',
  'NOT SET': 'NO DEFINIDO',
  'Start date': 'Fecha de inicio',
  'End date': 'Fecha final',
  'Expected Price': 'Precio esperado',
  'Product link': 'Conexión del producto',
  'no product': 'ningún producto',
  'Dispatched Items': 'Artículos enviados',
  'Valid Invoices': 'Facturas válidas',
  'Valid Amount Due': 'Importe válido adeudado',
  'Order’s items that you can dispatch to vendors': 'Solicite artículos que luego podrá enviar a los proveedores',
  'No Picture': 'Sin imagen',
  'No Data': 'Sin datos',
  'Dispatch order’s items to vendors': 'Enviar artículos de pedido a proveedores.',
  'Share by APP': 'Compartir a través de la aplicación',
  'Your vendor': 'Tu proveedor',
  'is inviting you to allocate your Orders': 'te invita a asignar tus pedidos',
  'Your store name': 'El nombre de tu tienda',
  'Dispatch order method': 'Método de envío del pedido',
  'All my unfulfilled / partially fulfilled orders': 'Todos mis pedidos no cumplidos/cumplidos parcialmente',
  'Cycle time': 'Tiempo del ciclo',
  'Every 1 day': 'Cada 1 día',
  'You can modify your order dispatch rules at any time in the menu': 'Puedes modificar las reglas de envío de tu pedido en cualquier momento en el menú',
  'Remind later': 'recuerda luego',
  'I agree': 'Estoy de acuerdo',
  'Shopify order': 'Orden de Shopify',
  'Dispatch to': 'Enviar para',
  'vendor(s)': 'proveedor(es)',
  'Dispatch in the Next Cycle': 'Envío en el próximo ciclo',
  'Dispatch Immediately': 'Enviar inmediatamente',
  'You can modify your store settings at any time in the menu': 'Puedes modificar la configuración de tu tienda en cualquier momento en el menú',
  'Newbie': 'Novato',
  'guide': 'guía',
  'Complete personal information': 'información personal completa',
  'Authorize to bind the store': 'Publicar push de producto',
  'Publish the push product': 'Publicar push de producto',
  'Start pulling orders': 'Empezar a sacar pedidos',
  'Already': 'Ya',
  'enter': 'entrar',
  'Add vendors success': 'Agregue éxito a los proveedores',
  'Please select the products': 'Por favor seleccione productos',
  'Please select the vendor': 'Por favor seleccione proveedor',
  'Please select the circle period': 'Seleccione el período del círculo',
  'Copy to clipboard failed, please copy manually': 'Error al copiar al portapapeles, cópielo manualmente',
  'Are you sure you want to cancel it?': '¿Estas seguro que quieres cancelar?',
  'Disputed order': 'Orden impugnada',
  'Awaiting Response': 'Esperando respuesta',
  'Search': 'Buscar',
  'Tracking Number': 'Número de rastreo',
  'Dispute Type': 'Tipo de disputa',
  'Expected Solution': 'Solución esperada',
  'Create Date': 'Fecha de Creación',
  'Last week': 'La semana pasada',
  'Last month': 'En el mes pasado',
  'OK': 'DE ACUERDO',
  'Type': 'Digitar',
  'Last three months': 'últimos tres meses',
  'The page you are looking for can': 'La página que estás buscando puede',
  'BACK TO HOMEPAGE': 'REGRESAR A LA PÁGINA PRINCIPAL',
  'Transform Your eCommerce business Into a Powerhouse': 'Convierta su negocio de comercio electrónico en una potencia',
  'Importify helps people like you to automate their dropshipping business.': 'Importify ayuda a personas como usted a automatizar su negocio de dropshipping.',
  'Product Research': 'Búsqueda de Producto',
  'Are you tired of searching the millions o products on AliExpress for potential winners?': '¿Estás cansado de buscar millones de productos en Aliexpress en busca de posibles ganadores?',
  'Are you tired of searching the millions o products on AliExpress for potential winners?': '¿Estás cansado de buscar millones de productos en Aliexpress en busca de posibles ganadores?',
  'One-click import products': 'Importar productos con un solo clic',
  'Import Product': 'importar producto',
  'Profit': 'Ganancia',
  'Import products from 30+ top dropshipping vendors, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart, and many more.': 'Importe productos de más de 30 proveedores de dropshipping, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart y muchos más.',
  'Pricing Rules': 'Reglas de precios',
  'Tired of calculating your target profit margin? Create pricing rules before importing, quickly adjust prices for sales.': '¿Estás cansado de calcular tu margen de beneficio objetivo? Cree reglas de precios antes de importar, ajuste rápidamente los precios de venta.',
  'Product Customization': '',
  'Use our chrome extension to modify the title, description, images, variants, pricing of the imported products.': 'Utilice nuestra extensión de Chrome para modificar el título, descripción, imágenes, variantes, precios de productos importados.',
  'Automate your orders': 'Automatiza tus pedidos',
  'The Importify Chrome Extension allows you to fulfill your orders quickly by placing the customer address automatically on the checkout page.': 'La extensión Importify Chrome le permite completar sus pedidos rápidamente al colocar automáticamente la dirección del cliente en la página de pago.',
  'Easy vendor Change': 'Fácil cambio de proveedor',
  'Replace your product vendor with a new vendor and connect them to your store with a click.': 'Reemplaza el proveedor del producto por un nuevo proveedor y conéctalo a tu tienda con un clic.',
  'Auto Dispatch Collections': 'Colecciones de despacho automático',
  'Auto-dispatch products to your Store collections supported on Shopify, WooCommerce, and Jumpseller.': 'Inserte productos automáticamente en las colecciones de su tienda con el respaldo de Shopify, WooCommerce y Jumpseller.',
  'Split Variants': 'Variantes divididas',
  'Variants': 'Variantes',
  'Importify gives you the ability to Split variants into Separate products.': 'Importify le brinda la posibilidad de dividir variantes en productos separados.',
  'Connect Existing Products': 'Conectar productos existentes',
  'If you have products that are already on a Shopify, Woocommerce or Jumpseller store, you can connect and manage them in Importify.': 'Si tienes productos que ya están en una tienda Shopify, Woocommerce o Jumpseller, puedes conectarlos y administrarlos en Importify.',
  'Add to Import List': 'Agregar a la lista de importación',
  'Add to Cart': 'Agregar al carrito',
  'View More': 'Ver más',
  'MORE': 'MÁS',
  'Categories': 'Categorías',
  'size': 'tamaño',
  'Stock Qty': 'Cantidad de existencias',
  'Total Revenue': 'Los ingresos totales',
  'Revenue': 'Ganancia',
  'Total Cost': 'Costo total',
  'Total Orders': 'Pedidos totales',
  'weight': 'Peso',
  'GO': 'IR',
  'Tag': 'Etiqueta',
  'Returnable with in': 'Retornable con en',
  'No Account?': '¿Sin cuenta?',
  'or Sign in with': 'o Inicia sesión con',
  'Already have an account?': '¿Ya tienes una cuenta?',
  'Find Products': 'encontrar productos',
  'My Import List': 'Mi lista de importación',
  'Please select a shipping address': 'Por favor seleccione una dirección de entrega',
  'Please select a shipping method': 'Seleccione un método de envío',
  'How to find products? Find products in': '¿Cómo encontrar productos? Encuentra productos en',
  'Products Market! Dropshipping from Worldwide to Worldwide! :': '¡Mercado de productos! ¡Envío directo desde todo el mundo a todo el mundo! :',
  'Email must be filled in': 'El correo electrónico debe ser completado.',
  'CAPTCHA must be filled in': 'CAPTCHA debe estar completado',
  'New password must be filled in': 'Se debe completar la nueva contraseña',
  'The new password and confirm password must be the same': 'La nueva contraseña y la contraseña de confirmación deben ser las mismas.',
  'The password must contain letters, and numbers, Minimum 8 characters': 'La contraseña debe contener letras y números, al menos 8 caracteres.',
  'Password reset successful': 'Restablecimiento de contraseña exitoso',
  'Password must be filled in': 'La contraseña debe ser completada.',
  'IN ALL CATEGORIES': 'EN TODAS LAS CATEGORÍAS',
  'Request Quotation': 'Solicitud de cotización',
  'More categories': 'Más categorías',
  'There are no products in this category': 'No hay productos en su cesta',
  'Place Order': 'Colocar ordem',
  'Choose orders': 'Elige pedidos',
  'Manage-Addresses': 'Administrar direcciones',
  'Default Address': 'Dirección predeterminada',
  'There is no Shipping method to reach the current address': 'No existe ningún método de envío para llegar a la dirección actual.',
  'Please select address': 'Por favor seleccione la dirección',
  'Order Information': 'información del pedido',
  'Product Price': 'Precio del producto',
  'Choose the products from your Shopify orders to allocated to your vendor': 'Elige productos de tus pedidos de Shopify para asignarlos a tu proveedor',
  'Click the Allocate to vendo button on the bottom of the page.': 'Clique no botão Alocar para Vendo na parte inferior da página.',
  'Tips: You can filter your orders here.': 'Consejos: Puedes filtrar tus pedidos aquí.',
  'Choose or create a new vendor.': 'Escolha ou crie um novo fornecedor.',
  'Click the Allocate button of the page,you can also allocate items from the same SPU to the same vendor': 'Haga clic en el botón Asignar en la página; también puede asignar artículos de la misma SPU al mismo proveedor.',
  'Un-allocate selected products': 'Desasignar productos seleccionados',
  'Total vendors': 'Proveedores totales',
  'Total orders': 'Pedidos totales',
  'Click the': 'clickea en el',
  'Allocate to vendor': 'Asignar al proveedor',
  'button on the bottom of the page.': 'botón en la parte inferior de la página.',
  'Total Product': 'Producto total',
  'Previous': 'Anterior',
  'Next': 'Próximo',
  'and Automate': 'y automatizar',
  'your entire dropshipping process': 'todo tu proceso de dropshipping',
  'Place 1 purchase orders': 'Realizar 1 orden de compra',
  'With just one click, you can easily allocate your stores orders to your vendors and the entire shipping process will be automatically completed.': 'Com apenas um clique, você pode facilmente alocar seus pedidos de lojas para seus fornecedores e todo o processo de envio será automaticamente concluído.',
  'Create a purchase orders to your vendor.': 'Crea órdenes de compra para tu proveedor.',
  'Choose the Shopify orders you want to sourcing,and click the Make a purchase order button of the page.': 'Elija los pedidos de Shopify que desea recibir y haga clic en el botón Realizar una orden de compra en la página.',
  'After confirming the operation,The purchase orders will be created successfully.': 'Luego de confirmar la operación las órdenes de compra se crearán exitosamente.',
  'Share purchase orders URL to your vendor and wait your vendor finish the invoice for you.': 'Comparta la URL de la orden de compra con su proveedor y espere a que el vendedor complete la factura por usted.',
  'Click the <b>Invite vendor to process</b> button on the bottom of the page.': 'Haga clic en el botón <b>Invitar proveedor a procesar</b> en la parte inferior de la página.',
  'You can send the purchase orders by Skype, WeChat or share by URL link.': 'Puede enviar órdenes de compra a través de Skype, WeChat o compartirlas mediante un enlace URL.',
  'I have finished the payment': 'terminé el pago',
  '<b>Invite vendor to process</b>': '<b>Invitar proveedor a procesar</b>',
  'Items total': 'Articulos totales',
  'You can create a new vendor or select an exist one.': 'Puede crear un nuevo proveedor o seleccionar uno existente.',
  'Amount Due': 'Importe debido ',
  'Payment method': 'Métodos de pago',
  'Click the button': 'Haga clic en el botón',
  'on the PO page when you finished your payment.': 'en la página de PO cuando haya terminado su pago.',
  'Through AliExpress proxy payment link': 'A través del enlace de pago proxy de Aliexpress',
  'Invite vendor to proess purchase order': 'Después de recibir al proveedorInvitar al proveedor a procesar la orden de compra',
  'After you receive the vendor': 'Después de recibir al proveedor',
  'Click the button <b>I have finished the payment</b> on the PO page when you finished your payment.': 'Haga clic en el botón <b>He completado el pago</b> en la página de orden de compra cuando haya completado su pago.',
  'After vendor shipped, Shopify order will be fulfilled automatically.': 'Una vez enviado desde el proveedor, tu pedido de Shopify se procesará automáticamente.',
  'After your vendor shipped your order, the shipping information will be sync to your Shopify store automatically.': 'Después de que tu proveedor envíe tu pedido, la información de envío se sincronizará automáticamente con tu tienda Shopify.',
  'Billing History': 'Historial de facturación',
  'Billing': 'cargar',
  'Quotation': 'Cita',
  'Search by bill number or order number': 'Buscar por número de cuenta o número de pedido',
  'Order Code': 'Código de orden',
  'Payment Method': 'Métodos de pago',
  'Payment': 'Pago',
  'Request for Quote': 'Solicitud de presupuesto',
  'Wait for import': 'Esperar a la importación',
  'My Profile': 'Mi perfil',
  'Allocate Orders for vendors': 'Asignar pedidos a proveedores',
  'and Automate <br />your entire dropshipping process': 'y automatiza <br />todo tu proceso de dropshipping',
  'With just one click, you can easily allocate your stores orders to': 'Con solo un clic, puede asignar fácilmente los pedidos de su tienda a',
  'your vendors and the entire shipping process will be automatically': 'tus proveedores y todo el proceso de envío será automáticamente',
  'completed.': 'terminado.',
  'How does it work?': '¿Como funciona?',
  'Connect to your store': 'Conéctate a tu tienda',
  'Specifications': 'Especificaciones',
  'Allocate orders to your vendors': 'Asigna pedidos a tus proveedores',
  'The vendor arranges the delivery': 'El proveedor organiza la entrega.',
  'Automatic fulfillment of your orders by': 'Cumplimiento automático de sus pedidos a través de',
  'See what our': 'Mira lo que nuestro',
  'customers talk about us.': 'Los clientes hablan de nosotros.',
  'More than 10,000+ customers around the world rely on us!': '¡Más de 10.000 clientes de todo el mundo confían en nosotros!',
  'Import List': 'Lista de importación',
  'Order': 'Orden',
  'about': 'acerca de',
  'Inquiry': 'Consulta',
  'Waiting': 'Esperar',
  'Trial calculation of shipping costs': 'Cálculo de prueba de los costos de envío.',
  'Return able with in': 'Volver capaz con en',
  'Push Setting': 'Configuración de empuje',
  'You can import and manage your AliExpress / Amazon products here': 'Puedes importar y gestionar tus productos Aliexpress/Amazon aquí',
  'before publishing them to your Shopify store. You can publish a': 'antes de publicarlos en tu tienda Shopify. Tu puedes',
  'product to different stores and add multiple tags to each products.': 'producto para diferentes tiendas y agregue múltiples etiquetas para cada producto.',
  'The Shopify orders still unfulfilled / partially fulfilled even if the vendor had upload tracking information.': 'Los pedidos de Shopify aún no se cumplen o se cumplen parcialmente a pesar de que el proveedor había subido información de seguimiento.',
  'You may download these tracking information and fulfilled these order in your Shopify store manually.': 'Puedes descargar esta información de seguimiento y completar estos pedidos en tu tienda Shopify manualmente.',
  'Click': 'Hacer clic',
  'New Type': 'Nuevo tipo',
  'need your reconfirm,': 'necesita tu reconfirmación',
  'here': 'aquí',
  'Operate': 'funcionar',
  'Pending': 'pendiente',
  'Approved': 'Aprobado',
  'to leam more': 'aprender más',
  'Import Product': 'importar producto',
  'Current Import Task': 'Tarea de importación actual',
  'Import Task': 'Importar tarea',
  'Add Tags': 'agregar etiquetas',
  'Tag management': 'Gestión de etiquetas',
  'Filter by stores': 'Filtrar por tiendas',
  'Upload Progress:': 'Progreso de la carga:',
  'Filter by tags': 'Filtrar por etiquetas',
  'Select an image': 'Seleccione una imagen',
  'Add Cost Range': 'Agregar rango de costos',
  'Rest of the price ranges': 'Resto de rangos de precios',
  'You can set a specific cent value for your price or comparison': 'Puede establecer un valor de centavo específico para su precio o comparación',
  'price.': 'precio',
  'Assign Cent': 'Asignar centavo',
  'According to store currency': 'Según la moneda de la tienda.',
  'Selected products': 'Productos seleccionados',
  'Also publish to Online Store': 'Publicar también en la tienda online.',
  'Loading time to long, check detailed progress': 'Tiempo de carga demasiado largo, verifique el progreso detallado',
  'Do you want to delete this task?': '¿Quieres eliminar esta tarea?',
  'Delete Completed': 'Eliminar completado',
  'Please select the stores': 'Por favor seleccione tiendas',
  'Please enter the tag': 'Por favor ingresa la etiqueta',
  'Please enter the New tag': 'Por favor inserte la etiqueta Nuevo',
  'The update has started, it will take a while to finish.': 'La actualización ha comenzado, tardará un poco en finalizar.',
  'Do you want to delete the product(s)?': '¿Quieres eliminar el producto(s)?',
  'Import success': 'Importación exitosa',
  'Please enter your store name to connect the dashboard page:': 'Ingrese el nombre de su tienda para vincular la página del panel:',
  'Coming soon': 'Pronto',
  'Track Number': 'código de rastreo',
  'Shopify order number': 'Número de pedido de Shopify',
  'Shopify store Name': 'Nombre de la tienda Shopify',
  'Products Market': 'Mercado de productos',
  'Qty Available': 'Cantidad disponible',
  'Keywords': 'Palabras clave',
  'First Available': 'Primero disponible',
  'Sort by': 'Ordenar por',
  'results': 'resultados',
  'Last 14 days': 'Últimos 14 días',
  'Please enter your shopify store name to connect the dashboard page': 'Ingresa el nombre de tu tienda Shopify para conectar la página del panel',
  'Assign orders to vender': 'Asignar órdenes para vender',
  'Step One: Select not shipped orders': 'Paso uno: seleccione pedidos no enviados',
  'Step Two: Confirm and send these orders to the vendor': 'Paso dos: Confirmar y enviar estos pedidos al proveedor.',
  'Choose one vendor': 'Elige un proveedor',
  'Please choose one vendor first': 'Por favor elija un proveedor primero',
  'All Stores': 'Todas las tiendas',
  'Abort to assign': 'Cancelar para asignar',
  'Go to Next': 'Ir al siguiente',
  'Go': 'Ir',
  'Confirm and send these orders to this vendor': 'Confirmar y enviar estos pedidos a este proveedor',
  'You can check the invoice by scan this QR Code or visit this URL by your web browser': 'Puede consultar la factura escaneando este código QR o visitar esta URL a través de su navegador web',
  'Time Created': 'Hora de creación',
  'Receiver': 'Receptor',
  'Company': 'Empresa',
  'Save and change the vendor': 'Guardar y cambiar proveedor',
  'Selected saved': 'Seleccionado guardado',
  'Now you can': 'Ahora usted puede',
  'Send purchase orders to the vendor': 'Enviar órdenes de compra al proveedor.',
  'Check the list again': 'Revisa la lista nuevamente',
  'Send purchase orders to the vendor': 'Enviar órdenes de compra al proveedor.',
  'A new order successfully created': 'Un nuevo pedido creado exitosamente',
  'All Unfulfilled Shopify Orders': 'Todos los pedidos de Shopify no cumplidos',
  'Quick select of Shopify order items': 'Selección rápida de artículos de pedido de Shopify',
  'Shopify order total': 'Pedidos totales de Shopify',
  'Order Date': 'Fecha de solicitud',
  'Periods of orders:': 'Periodos de pedido:',
  'Quick select of Shopify order items': 'Selección rápida de artículos de pedido de Shopify',
  'shopify orders have been selected and can now be dispatched to your vendor': 'Los pedidos de Shopify han sido seleccionados y ahora pueden enviarse a su proveedor',
  'Dispatch to the vendor': 'Envío al proveedor',
  'Please choose an vendor': 'Por favor elige un proveedor',
  'Please choose an item': 'Por favor elige un artículo',
  'A new order successfully created': 'Un nuevo pedido creado exitosamente',
  'All shopify unshipped orders': 'Todos los pedidos de Shopify no enviados',
  'All undelivered shopify orders': 'Todos los pedidos de Shopify no entregados',
  'shopify orders have been selected and can now be assigned to your suppliers': 'Los pedidos de Shopify han sido seleccionados y ahora se pueden asignar a tus proveedores.',
  'customers successfully added': 'los clientes han agregado exitosamente',
  'Payment Record': 'Registro de pago',
  'Recharge': 'Recargar',
  'Payment Record': 'Registro de pago',
  'trade code': 'código Comercial',
  'Payment Amount': 'pago de la cantidad',
  'Payment Status': 'Estado de pago',
  'Payment Number': 'Número de pagamento',
  'Payment Date': 'Día de paga',
  'You have authorized a Shopify store，you could': 'Has autorizado una tienda Shopify que podría',
  'authorize to login': 'autorizar inicio de sesión',
  'to view details。': 'para ver detalles',
  'If it is necessary to process your shopify order or to achieve an automatically update for the shipping information toshopify, you could': 'Si necesitas procesar tu pedido de Shopify u obtener una actualización automática de tu información de envío de Shopify, puedes',
  'connect your Shopify store': 'conecta tu tienda Shopify',
  'and let us help you.': 'y déjanos ayudarte.',
  'If you have authorized a Shopify store, after the vendor delivers the items, the shipping information will been synchronized to your Shopify store which you had authorized.': 'Si autorizaste una tienda Shopify, después de que el proveedor entregue los artículos, la información de envío se sincronizará con la tienda Shopify que autorizaste.',
  '(If there are some Shopify stores in the order list below that have not been authorized, you can': '(Si hay algunas tiendas Shopify en la lista de pedidos a continuación que no han sido autorizadas, puedes',
  'authorize a new one': 'autorizar un nuevo',
  'If you need to view your recent orders, please click': 'Si necesita ver sus pedidos recientes, haga clic en',
  'My to view details.': 'Para ver los detalles.',
  'Cancelled': 'cancelado',
  'Purchase Order': 'Ordem de compra',
  ' The vendor has shipped the product(s), but the shipping information has not been sync to your Shopify store.': 'El proveedor envió los productos, pero la información de envío no se sincronizó con tu tienda Shopify.',
  'Will not ship': 'Não enviará',
  'Not shipped': 'No enviado',
  'Quantity': 'cuantía',
  'Subtotal': 'total parcial',
  'Logistics fee': 'Tarifa de logística',
  'The webmaster has confirmed the  payment': 'El webmaster confirmó el pago.',
  'The vendor has confirmed the payment': 'El vendedor confirmó el pago.',
  'The vendor start delivery the orders': 'El proveedor comienza a entregar pedidos.',
  'Shipping costs': 'Costos de envío',
  'Discount': 'Descuento',
  'Amount Due': 'Importe debido ',
  'Payment method': 'Métodos de pago',
  'Via paypal': 'Vía paypal',
  'Through AliExpress proxy payment link': 'A través del enlace de pago proxy de Aliexpress',
  'Through a bank transfer': 'A través de una transferencia bancaria',
  'According to the vendor’s setting you can go to': 'Según la configuración del proveedor, puede ir a',
  'Aliexpress to buy the item in the vendor’s store': 'Aliexpress para comprar el artículo en la tienda del proveedor.',
  'Go to AliExpress NOW': 'Ir a Aliexpress AHORA',
  'Invite vendor to process purchase order': 'Invitar al proveedor a procesar la orden de compra.',
  'webmaster has confirmed the payment': 'El vendedor confirmó el pago.',
  'The vendor has confirmed the payment': 'El proveedor comienza a entregar pedidos.',
  'The vendor start delivery the orders': 'El proveedor comienza a entregar pedidos.',
  'The webmaster has confirmed the payment': 'El webmaster confirmó el pago.',
  'The vendor has confirmed the payment': 'El vendedor confirmó el pago.',
  'The vendor start delivery the orders': 'El proveedor comienza a entregar pedidos.',
  'According to the vendor’s setting you can go to': 'Según la configuración del proveedor, puede ir a',
  'Aliexpress to buy the item in the vendor’s store': 'Aliexpress para comprar el artículo en la tienda del proveedor.',
  'Go to AliExpress NOW': 'Ir a Aliexpress AHORA',
  'Invite vendor': 'Invitar proveedor',
  'You can download order': 'Você pode baixar ordem',
  'Timeline': 'Línea de tiempo',
  'No more comment': 'No mas comentarios',
  'You have connected a Shopify store before, for your data security, please': '¿Has conectado una tienda Shopify antes? Para la seguridad de tus datos, por favor.',
  'log in the Shopify account': 'inicia sesión en tu cuenta de Shopify',
  'you connected and view this invoice': 'iniciaste sesión y viste esta factura',
  'The current invoice page is for preview purposes only, if you are sending to a customer please use the': 'La página de factura actual es solo para fines de visualización; si la envía a un cliente, utilice el',
  'Invoice Preview': 'Vista previa de factura',
  'As shown in Figure 1:': 'Como se indica en la figura 1:',
  'As shown in Figure 2:': 'Como se indica en la figura 2:',
  'I knew it': 'Yo sabía',
  'Connect your Shopify store': 'Conecta tu tienda Shopify',
  'you can dispatch the Shopify orders to your vendors after the authorization and the shipping information will be automatically synchronized to your Shopify Store.': 'Puedes enviar pedidos de Shopify a tus proveedores después de la autorización y la información de envío se sincronizará automáticamente con tu tienda Shopify.',
  'Enter your existing Shopify URL bellow and click “connect”, you will be redirect to Shopify to connect your account to (the authorization will be also completed).': 'Ingresa tu URL de Shopify existente a continuación y haz clic en "conectar", serás redirigido a Shopify para conectar tu cuenta (también se completará la autorización).',
  'Connect your Shopify store': 'Conecta tu tienda Shopify',
  'Connect a store and fulfill your Shopify orders, sync logistics information to your Shopify store automatically.': 'Conecte una tienda y complete sus pedidos de Shopify, sincronice la información de logística con su tienda Shopify automáticamente.',
  'Enter your existing Shopify store URL bellow and you': 'Ingresa la URL de tu tienda Shopify existente a continuación y',
  'Purchase order base information': 'Información base de la orden de compra',
  'Total number of orders': 'Número total de pedidos',
  'Order items': 'Encargar artículos',
  'Total goods': 'Los activos totales',
  'Total purchase amount': 'Valor total de compra',
  'Shipment statistics': 'Estatísticas de remessas',
  'Number of items shipped': 'Número de artículos enviados',
  'Amount of goods shipped': 'Cantidad de mercancías enviadas',
  'Number of items not shipped': 'Número de artículos no enviados',
  'Amount of unshipped goods': 'Cantidad de mercancías descargadas',
  'Shipment rate': 'Gastos de envío',
  'Number of items not shipped': 'Número de artículos no enviados',
  'Amount of non-delivery items': 'Número de artículos no entregados',
  'Set the vendor’s SKU': 'Definir el SKU del proveedor',
  'Set the supply price': 'Establecer el precio de suministro',
  'Set up payment methods': 'Configurar métodos de pago',
  'Payment Methods': 'Métodos de pago',
  'Send the invoice to the customer': 'Enviar la factura al cliente.',
  'The fields to be exported': 'Los campos a exportar',
  'the sequence is adjustable by dragging forward and backward': 'la secuencia es ajustable arrastrando hacia adelante y hacia atrás',
  'You have not yet selected the fields that can be exported. Please select below.': 'Aún no ha seleccionado los campos que se pueden exportar. Por favor seleccione a continuación.',
  'optional fields': 'campos opcionales',
  'unexported fields': 'campos no resueltos',
  'custom field name': 'nombre de campo personalizado',
  'is displayed as': 'se muestra como',
  'Export order': 'Orden de exportación',
  'BILL To': 'COBRAR A',
  'Invoice Number': 'Número de factura',
  'Invoice Date': 'Fecha de la factura',
  'Amount Due (US$)': 'Importe debido ',
  'The supplier has shipped, but the logistics information has not been synced to the Shopify shop.': 'El proveedor realizó el envío, pero la información de logística no se sincronizó con la tienda Shopify.',
  'Reason': 'Razón',
  'The supplier has shipped and the logistics information has been synced to the Shopify shop.': 'El proveedor realizó el envío y la información logística se sincronizó con la tienda Shopify.',
  'Mark as shipped': 'Marcar como enviado',
  'The webmaster has confirmed the payment': 'El webmaster confirmó el pago.',
  'The vendor has confirmed the payment': 'El vendedor confirmó el pago.',
  'According to the vendor’s setting you can go to': 'Según la configuración del proveedor, puede ir a',
  'Aliexpress to buy the item in the vendor’s store': 'Aliexpress para comprar el artículo en la tienda del proveedor.',
  'to finish the payment': 'para finalizar el pago',
  'Go to AliExpress NOW': 'Ir a Aliexpress AHORA',
  'The vender has confirmed the payment.': 'El vendedor confirmó el pago.',
  'Remove from order': 'Quitar del pedido',
  'You are deleting this product from purchase order. You can still find it': 'Estás eliminando este producto de la orden de compra. todavía puedes encontrarlo',
  'in your': 'en tu',
  'Total Amount:': 'Cantidad total:',
  'Pay with': 'Pagar con',
  'Your order has been successfully paid and the order amount is': 'Su pedido ha sido pagado exitosamente y el monto del pedido es',
  'Close Pay': 'Close pay',
  'Remove success': 'Eliminar éxito',
  'Track number must be entered': 'Se debe ingresar el código de seguimiento.',
  'Track company must be entered': 'La empresa de senderos debe estar registrada.',
  'Track company must be entered': 'La empresa de senderos debe estar registrada.',
  'Track Url must be entered': 'Se debe ingresar la URL de la pista',
  'Please enter Shopify store URL': 'Ingresa la URL de tu tienda Shopify',
  'Under authorization, please wait…': 'Bajo autorización, por favor espere...',
  'After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up': 'Después de la confirmación del pago, notificaremos al proveedor por correo electrónico. También puede notificar proactivamente al proveedor para acelerar la entrega.',
  'Have you finished the payment': 'Has terminado el pago',
  'At least one field must be selected': 'Se debe seleccionar al menos un campo',
  'The name of the customized header cannot be empty': 'El nombre del encabezado personalizado no puede estar vacío',
  'Export the order information': 'Exportar información del pedido',
  'The size of the uploaded image should not exceed 500kb!': '¡El tamaño de la imagen cargada no debe exceder los 500 kb!',
  'Simple, Transparent Pricing': 'Precios simples y transparentes',
  'mportify offers three types of plans; Basic plan, Premium plan, and Gold plan.': 'mportify ofrece tres tipos de planes: plan Básico, plan Premium y plan Gold.',
  'Each account starts with a day trial, and your billing cycle begins after the free trial period has ended.': 'Cada cuenta comienza con una prueba de un día y su ciclo de facturación comienza una vez finalizado el período de prueba gratuito.',
  'Importify Plans<': 'Planes de importación',
  'Basics': 'Básico',
  'Premium': 'De primera calidad',
  'Gold': 'De oro',
  'Offers': 'Ofertas',
  'Amazon & Aliexpress importer': 'Importador de Amazon y Aliexpress',
  'Semi-automatic order fulfillment (Supported on Aliexpress, Amazon, Dhgate & Wonify)': 'Cumplimiento de pedidos semiautomático (compatible con Aliexpress, Amazon, Dhgate y Wonify)',
  'Product Customisation': 'Personalización del producto',
  'Description text': 'Texto descriptivo',
  'The tax-inclusive price of an item is calculated based on the item price set by the seller and the applicable tax rate. You can contact the seller for more details.': 'El precio de un artículo con impuestos incluidos se calcula en función del precio del artículo establecido por el vendedor y la tasa impositiva aplicable. Puede ponerse en contacto con el vendedor para obtener más detalles.',
  'VAT rate(Destination only in Germany)': 'Tipo de IVA (solo destino Alemania)',
  'Shipping options': 'Opciones de envío',
  'Fulfilment costs': 'Costos de cumplimiento',
  'Fulfillment Costs': 'Costos de cumplimiento',
  'Shipping time': 'hora de envío',
  'Update Product': 'Actualizar producto',
  'Shipping Time': 'hora de envío',
  'All Action': 'Toda la acción',
  'After Sales Service': 'Servicio postventa',
  'product description': 'descripción del producto',
  'Download the attachment': 'Descargue el archivo adjunto',
  'product information': 'Información del Producto',
  'Product category': 'categoria de producto',
  'Specification': 'Especificación',
  'material': 'material',
  'Store price': 'Precio de la tienda',
  'Delivery: Varies for items shipped from an international location': 'Entrega: Varía para artículos enviados desde una ubicación internacional.',
  'This product has been removed from the shelves': 'Este producto ha sido retirado de los estantes.',
  'Return': 'Retorno',
  'Enter Store name': 'Introduce el nombre de la tienda',
  'Enter vendor name': 'Introduzca el nombre del proveedor',
  'Open in website': 'Abrir en el sitio web',
  'Retail price:': 'Precio al por menor:',
  'NOT SET': 'NO DEFINIDO',
  'Supplier price': 'Precio del proveedor',
  'GENERAL INFORMATION': 'INFORMACION GENERAL',
  'CONTACT': 'CONTACTO',
  'SOCIAL': 'SOCIAL',
  'More': 'Más',
  'Store Name': 'Nombre de la tienda',
  'Notification Settings': 'Configuración de las notificaciones',
  'I want to receive notfications by': 'quiero recibir notificaciones por',
  'Mobile phone': 'Teléfono móvil',
  'Email': 'Correo electrónico',
  'Allow system notifhcations': 'Permitir nociones del sistema',
  'Allow Customer Services from': 'Habilitar servicio al cliente',
  'Allow Dropshipping News': 'Permitir noticias de dropshipping',
  'Save Changes': 'Guardar ediciones',
  'Reset All': 'Resetear todo',
  'Purchase List': 'Lista de compras',
  'Complete your Skype or WhatsApp details to get rewards': 'Completa tus datos de Skype o WhatsApp para obtener recompensas',
  'You have completed the Skype or WhatsApp, you can receive a free gift package.': 'Completaste Skype o WhatsApp, puedes recibir un paquete de regalo gratis.',
  'The captcha was sent successfully': 'El captcha fue enviado exitosamente',
  'Order Details': 'Detalles del pedido',
  'Sub Order NO': 'Suborden NO',
  'Sold Out': 'Vendido',
  'Status': 'Estado',
  'Images': 'Imágenes',
  'Product Information': 'Información sobre el producto.',
  'Unit Price': 'precio unitario ',
  'Quantity': 'cuantía',
  'Sub Total': 'total parcial',
  'Grand Total': 'Total general',
  'Address': 'DIRECCIÓN',
  'Tracking Number:': 'Número de rastreo:',
  'Total Amount': 'Cantidad total',
  'Products Cost': 'Costo de los productos',
  'Cost': 'Costar',
  'Weight': 'Peso',
  'Shipping': 'Transporte marítimo',
  'Enter SKU': 'Introduce el SKU',
  'Package': 'Paquete',
  'I agree the': 'Estoy de acuerdo con el',
  'Pay NOW': 'Pagar ahora',
  'All vendors': 'Todos los proveedores',
  'Dispatch Status': 'Estado de envío',
  'You could select multiple vendors': 'Puedes seleccionar múltiples proveedores',
  'Shipping Cost Included': 'Costo de transporte incluido',
  'Do you want to cancel the order?': '¿Quieres cancelar el pedido?',
  'Order was successfully cancelled': 'El pedido fue cancelado exitosamente',
  'Choose your recharge package': 'Elige tu paquete de recarga',
  'Choose your payment method': 'Escoja su método de pago',
  'Payment Record': 'Registro de pago',
  'Terms of use': 'Terminos de uso',
  'Refund Policy': 'Politica de reembolso',
  'History': 'Historia',
  'Platform Reply': 'Respuesta de plataforma',
  'Picture': 'Marco',
  'You can only upload 5 pictures': 'Sólo puedes enviar 5 fotos.',
  'Product Title:': 'Titulo del producto:',
  'Price Range:': 'Gama de precios:',
  'Base Information': 'Información básica',
  'Product Links:': 'enlace de producto',
  'Product Description:': 'Descripción del producto:',
  'Publish': 'Publicar',
  'Trade Name': 'Nombre comercial',
  'Only matching last': 'Sólo coincide con el último',
  'Save Rule': 'Guardar regla',
  'Please select the Period': 'Por favor seleccione el Periodo',
  'Please select the vendor visibility': 'Seleccionar visibilidad del proveedor',
  'Automatic dispatch orders setting': 'Configurar pedidos de envío automático',
  'Show hidden orders': 'Mostrar pedidos ocultos',
  'Choose my all Shopify products': 'Elige todos mis productos de Shopify',
  'shopify orders have been selected and can now be dispatched to your vendors': 'Los pedidos de Shopify han sido seleccionados y ahora pueden enviarse a sus proveedores',
  'Dispatch to the vendor': 'Envío al proveedor',
  'My all Shopify products': 'Todos mis productos Shopify',
  'days unfulfilled orders': 'días de pedidos no cumplidos',
  'Dispatch rule TEST': 'Regla de envío de PRUEBA',
  'According to your settings, we found some data for you:': 'Según su configuración, encontramos algunos datos para usted:',
  'THE BRIDGE CONNECTING SUPPLIERS AND YOU, MAKING DROPSHIPPING BUSINESS MORE CONVENIENT': 'EL PUENTE QUE CONECTA A LOS PROVEEDORES Y A USTED, HACIENDO EL NEGOCIO DE DROPSHIPPING MÁS CONVENIENTE',
  'Your supplier only needs to register an account to quickly build the system': 'Su proveedor sólo necesita registrar una cuenta para construir rápidamente el sistema',
  'One-click hosting of stores, fast synchronization of orders': 'Alojamiento de tienda con un solo clic, sincronización rápida de pedidos',
  'Automatically synchronize logistics information': 'Sincronizar automáticamente la información logística',
  'Quick Access': 'Acceso rapido',
  'Do you have an BCN supplier for you': '¿Tienes un proveedor BCN para ti?',
  'Already have a supplier': 'Ya tienes proveedor',
  'I don': 'Yo no',
  'Let your supplier fulfill your order more systematically, copy the link and send it to your supplier, and immediately invite your supplier to settle in,': 'Deje que su proveedor cumpla con su pedido de manera más sistemática, copie el enlace y envíelo a su proveedor, e invítelo inmediatamente a configurar,',
  '2024-2024BCN All Rights Reserved': '2024-2024BCN Todos los derechos reservados',
  'Waiting for the payment result to be returned': 'Esperando que se devuelva el resultado del pago',
  'You may need to wait a little while': 'Quizás tengas que esperar un poco',
  'View order': 'Ver pedido',
  'You have successfully completed your order': 'Has completado exitosamente tu pedido',
  'Payment failed': 'Pago fallido',
  'Sorry, we can': 'Lo siento, podemos',
  'Product Details': 'Detalles del producto',
  'Please check the purchase products': 'Por favor revisa los productos de compra.',
  'out of stock': 'vendido',
  'Bonus': 'Bonificación ',
  'Quality': 'Calidad',
  'Credits': 'Créditos ',
  'Unable to be sourced': 'no puedo conseguir',
  'Combination payment': 'Pagos combinados',
  'Please enter the balance to be used': 'Por favor ingresa el saldo a utilizar',
  'Rules: The input amount should be less than the order amount, and only two decimal places': 'Reglas: El pago inicial debe ser menor que el valor del pedido y solo dos decimales.',
  'After the combination payment is enabled, the account balance will be deducted first, and the rest will be paid by other selected methods': '',
  'Payment method': 'Métodos de pago',
  'Amount due (including freight)': 'Importe debido (incluye el envío)',
  'Submit orders': 'Enviar pedidos',
  'Please select the goods first!': '¡Seleccione los productos primero!',
  'Please choose the logistics channel': 'Por favor elija el canal de logística',
  'Please try again later ~': 'Vuelve a intentarlo más tarde',
  'Wholesale order': 'Orden al por mayor',
  'offline order': 'pedido fuera de línea',
  'Shipping Address': 'Dirección de envio',
  'User Name': 'Nombre de usuario',
  'Users login': 'Inicio de sesión de usuario',
  'Password': 'Contraseña',
  'Add a new store': 'agregar una nueva tienda',
  'Auth Status': 'estado de autenticación',
  'All Status': 'Todo el estado',
  'Authorized': 'Autorizado',
  'Unauthorized': 'No autorizado',
  'Creation date': 'fecha de creación',
  'Auth date:': 'Fecha de autenticación:',
  'no product': 'ningún producto',
  'Delete Task': 'Eliminar tarea',
  'Sale price': 'Precio de venta',
  'Select the task': 'Selecciona la tarea',
  'Do you want to delete this task?': '¿Quieres eliminar esta tarea?',
  'Create a ticket': 'Crear un billete',
  'Request': 'Orden',
  'Store name:': 'Nombre de la tienda',
  'No Message': 'Sin mensaje',
  'Send': 'Mandar',
  'Please enter a value greater than 0': 'Introduzca un valor mayor que 0',
  'Please enter a value less than the total amount of the order': 'Introduzca un valor inferior al valor total del pedido',
  'Tell us more about your request': 'Cuéntanos más sobre tu pedido',
  'What is your Shopify store name?': '¿Cuál es el nombre de tu tienda Shopify?',
  'Transaction': 'Transacción',
  'normal bill': 'factura regular',
  'Account payee': 'beneficiario de la cuenta',
  'Payment code': 'codigo de pago',
  'Third Party Number': 'Número de terceros',
  'The export of time': 'La exportación del tiempo',
  'Petitioner：': 'Peticionario',
  'Payment code': 'codigo de pago',
  'Trade type': 'tipo de negociación',
  'Rewards QTY left': 'Recompensas de cantidad restante',
  'Trade amount': 'Valor comercial',
  'Payment type': 'Tipo de pago',
  'Amount paid/Credit payment': 'Monto pagado/pago de crédito',
  'Payment order': 'Orden de pago',
  'Refund order': 'Orden de reembolso',
  'Wallet card': 'portafolio',
  'Manual deduction': 'Deducción manual',
  'Paying': 'Pago',
  'Account paid': 'Cuenta de pago',
  'Refunded': 'Reintegrado',
  'Cancel': 'cancelar ',
  'Vender Name': 'Vender Nombre',
  'AliExpress Shop Name': 'Nombre de la tienda Aliexpress',
  'Product allocated': 'Producto asignado',
  'Purchase amount:': 'Monto de la compra:',
  'Created at:': 'Creado en:',
  'Sourcing Now': 'Abastecimiento ahora',
  'Latest cooperation at:': 'Última cooperación en:',
  'How to add': 'Cómo agregar',
  'By Vendor Name': 'Por nombre del proveedor',
  'Email Security Verification': 'Verificación de seguridad del correo electrónico',
  'For security reason.We need to verify your email address.Please click': 'Por razones de seguridad. Necesitamos verificar su dirección de correo electrónico. Haga clic.',
  'Send': 'Mandar',
  'Pay': 'Pagar',
  'Verify': 'Verificar',
  'My Wallet': 'Mi billetera',
  'My Balance': 'Mi balance',
  'brokerage': 'corretaje',
  'withdraw': 'Para eliminar',
  'Please enter the withdrawal amount': 'Por favor ingrese el monto del retiro',
  'tip': 'consejo',
  'Your Total Rewards': 'Tus recompensas totales',
  'orders QTY': 'cantidad de pedidos',
  'We Accept': 'Aceptamos',
  'Other details': 'Otros detalles',
  'Payment account': 'Cuenta de pago',
  'Payment voucher': 'Comprobante de pago',
  'Sign up here': 'registrar aquí',
  'Forgot your password?': '¿Olvidaste tu contraseña?',
  'Payment instructions：': 'Instrucciones de pago',
  'Charging Method': 'Método de carga',
  'Pay with Credit Card': 'Paga con tarjeta de crédito',
  'Paypal Payment Amount': 'Monto del pago',
  'The amount of each recharge must be more than 100 dollars.': 'El valor de cada recarga debe ser mayor a 100 dólares.',
  'Please verify your email address here,': 'Por favor verifique su dirección de correo electrónico aquí,',
  'Withdraw Cash': 'Sacar dinero',
  'I see': 'Veo',
  'Trade status': 'situación empresarial',
  'Enter Verification Code': 'Introduzca el código de verificación',
  'Verify Email': 'Revisar correo electrónico',
  'Bill Number': 'Número de factura',
  'Order Code': 'Código de orden',
  'Transfer ID': 'Identificación de Transferencia',
  'wallet recharge': 'recarga de billetera',
  'Payment Method': 'Métodos de pago',
  'The minimum payment is $100.': 'El pago mínimo es de $100.',
  'Powered by': 'Alimentado por',
  'Card Number': 'numero de tarjeta',
  'Expiration': 'Vencimiento',
  'All transactions are secure and encrypted.': 'Todas las transacciones son seguras y encriptadas.',
  'Account Balance': 'Saldo de la cuenta',
  'Withdrawal amount': 'Cantidad retirada',
  'We will charge you 5% fee when withdrawing.': 'Le cobraremos una tarifa del 5% al ​​retirar.',
  'Send Verification Code': 'Envíe el código de verificación',
  'The verification code will be sent to your email': 'El código de verificación se enviará a su correo electrónico.',
  'Please enter your withdrawal amount': 'Por favor ingrese el monto de su retiro',
  'Please enter your account': 'Por favor ingresa tu cuenta',
  'Please enter your account name': 'Ingrese el nombre de su cuenta',
  'Please enter the code': 'Por favor ingresa el código',
  'Please enter the recharge amount': 'Por favor introduce el importe de la recarga',
  'The minimum payment is $100': 'El pago mínimo es $100',
  'Payment successful': 'Pago exitoso',
  'The Code was sent successfully': 'El código fue enviado exitosamente.',
  'Our app is now updating the page you are visiting, we will auto refresh this page in 10 seconds.': 'Nuestra aplicación ahora está actualizando la página que está visitando; actualizaremos automáticamente esta página en 10 segundos.',
  'I got it, please refresh it now': 'Ya lo tengo, actualízalo ahora.',
  'Estimated start time:': 'Hora de inicio estimada:',
  'Security Verification': 'Control de seguridad',
  'We noticed you haven': 'Notamos que te refugiaste',
  'Verify': 'Verificar',
  'Email Security Verification': 'Verificación de seguridad del correo electrónico',
  'For security reason.We need to verify your email address.': 'Por razones de seguridad. Necesitamos verificar su dirección de correo electrónico.',
  'Send To:': 'Enviar para:',
  'Please click': 'Por favor haz click',
  'please enter': 'Por favor inserte',
  'Work order number': 'Número de orden de servicio',
  'Pending review': 'Revisión pendiente',
  'agree': 'llegar a un acuerdo',
  'reject': 'rechazar',
  'After-sales work order': 'Orden de trabajo postventa',
  'Work order type': 'Tipo de orden de servicio',
  'order number': 'Numéro del pedido',
  'client': 'cliente',
  'shop': 'tienda',
  'creation time': 'tiempo de creación',
  'Approval': 'Aprobación',
  'Transaction Type': 'Tipo de transacción',
  'question type': 'tipo de pregunta',
  'Problem Description': 'Descripción del problema',
  'afterSales': 'post venta',
  'Refund': 'Reembolso',
  'Replacement': 'Reemplazo',
  'modify the related information for customer': 'modificar información relacionada con el cliente',
  'cancel order': 'cancelar orden',
  'No tracking information': 'No hay información de seguimiento',
  'Tracking information not update too long time': 'La información de seguimiento no se ha actualizado durante mucho tiempo.',
  'Item damage': 'Daño del artículo',
  'Item missing': 'Objeto perdido',
  'Send wrong product': 'Enviar producto incorrecto',
  'Send wrong address': 'Enviar dirección incorrecta',
  'On hold order': 'En espera',
  'optional': 'opcional',
  'after sales': 'post venta',
  'Please select an order first': 'Por favor seleccione un pedido primero',
  '请输入': 'por favor ingrese',
  '工单号': 'Número de orden de trabajo',
  '同意': 'acuerdo',
  "售后工单": "Orden de trabajo posventa",
  '工单类型': 'Tipo de orden de trabajo',
  '客户名称': "nombre del cliente",
  '审批': "Aprobación",
  "交易类型": "Tipo de transacción",
  "问题类型": "Tipo de pregunta",
  "问题描述": "Descripción del problema",
  '退款': "Reembolso",
  '更换': "Reemplazo",
  "修改客户相关信息": "modificar la información relacionada con el cliente",
  '取消订单': "cancelar pedido",
  '无跟踪信息': "Sin información de seguimiento",
  '跟踪信息长时间未更新': "La información de seguimiento no se ha actualizado durante mucho tiempo",
  '物品损坏': "Daño del artículo",
  '物品丢失': "Falta de artículo",
  '发送错误产品': "Enviar producto incorrecto",
  '发送错误地址': "Enviar dirección incorrecta",
  '订单暂停': "Pedido en espera",
  '选填': "opcional",
  "订单信息": "Información del pedido",
  "第三方订单编号": "Número de orden de terceros",
  "订单编号": "Número de orden",
  "店铺": "Tiendas",
  "创建时间": "Tiempo de creación",
  "开泰银行支付": "Pago del Banco Kaieteur",
  "供应商已发货，但物流信息未同步到Shopify店铺。": "El proveedor ha enviado, pero la información logística no se ha sincronizado con la tienda Shopify.",
  "供应商已发货，物流信息已同步到Shopify店铺。": "El proveedor ha enviado y la información logística se ha sincronizado con la tienda Shopify.",
  "您还没有选择可导出的字段。请在下方选择。": "Todavía no ha seleccionado los campos que se pueden exportar. Por favor, seleccione a continuación.",
  "采购订单基础信息": "Información básica de la orden de compra",
  "订单总数": "Número total de órdenes",
  "订单商品项": "Artículos del pedido",
  "合计商品总数": "Total de productos",
  "合计采购金额": "Monto total de la compra",
  "发货情况统计": "Estadísticas de envío",
  "已发货商品数": "Número de artículos enviados",
  "已发货商品额": "Monto de productos enviados",
  "未发货商品数": "Número de artículos no enviados",
  "未发货商品额": "Monto de productos no enviados",
  "发货率": "Tasa de envío",
  "不发货商品数": "Número de artículos no enviados",
  "不发货商品额": "Monto de artículos no entregados",
  "当前发票页面仅供预览，如果发送给客户请使用发票编辑页面的'发送发票'功能！": "La página actual de la factura es solo para fines de vista previa, si va a enviarla a un cliente, por favor, utilice la función 'Enviar factura' en la página de edición de la factura.",
  "发票预览": "Vista previa de la factura",
  "如图一所示:页面顶部“发送发票”按钮": "Como se muestra en la Figura 1: Botón 'Enviar factura' en la parte superior de la página",
  "如图二所示:页面底部'发送发票'按钮": "Como se muestra en la Figura 2: Botón 'Enviar factura' en la parte inferior de la página",
  "商品列表页": "Página de lista de productos",
  "底部": "Parte inferior",
  "顶部": "Parte superior",
  "请输入单号范围": "Por favor, introduzca el rango de números individuales",
  "起始单号必须小于结束单号": "El número de orden inicial debe ser menor que el número de orden final",
  "确定删除该商品？": "¿Seguro que desea eliminar este producto?",
  "确定": "OK",
  "取消": "cancelar",
  "已取消": "Cancelado",
  "选取文件": "Seleccionar archivo",
  "点击下载": "Haga clic para descargar",
  "订单导入模板": "Plantilla de importación de pedidos",
  "请按规范填写表格，仅支持xlsx格式文件": "Por favor, complete el formulario según las especificaciones, solo se admiten archivos en formato xlsx",
  "上传的文件不能超过2M，只处理前99条数据": "El archivo cargado no puede superar los 2M, y solo se procesarán los primeros 99 datos",
  '待审核': 'No revisado',
  '通过': 'aceptar',
  '拒绝': 'rechazar',
  "余额":"saldo",
  "线下":"fuera de línea",
  '提现列表': "Lista de Retiros",
  "提现方式":"Método de Retiro",
  "状态": 'Estado de Aprobación',
  '警告提示': 'Consejos de Advertencia',
  '该分类下目前还没有商品': 'Actualmente no hay productos en esta categoría',
  '最大建议尺寸 200px * 200px, 图片可自适应': 'Tamaño máximo recomendado 200px * 200px, la imagen puede ser adaptativa',
  '添加图片': 'agregar imagen',
  '更改': 'cambiar',
  '删除': 'eliminar',
  '请联系供应商，完善站长外部编号': 'Por favor, contacte al proveedor para mejorar el número externo del webmaster',
  '返回': 'Volver',
  "上传图片大小不能超过 500kb!": "El tamaño de la imagen cargada no debe superar los 500kb!",
  "描述文字": "Texto de Descripción",
  "履行费用": "Costos de Cumplimiento",
  "发货时效": "Tiempo de Envío",
}