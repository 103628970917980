export default {
  "Product category name": "Nom du produit",
  "Category Name": "Nom de catégorie",
  "Please enter the first name": "Veuillez entrer le nom",
  "Please enter the last name": "Veuillez entrer votre nom de famille",
  "Please enter the address": "Veuillez entrer l'adresse",
  "Please enter the country": "Veuillez entrer le pays",
  "Please enter the province": "Veuillez entrer la province",
  "Please enter the city": "Veuillez entrer la ville",
  "Please enter the phone": "Veuillez entrer le numéro de téléphone",
  "Please enter the postcode": "Veuillez entrer le code postal",
  "Total Payment": "règlement de la totalité",
  "Total Merchandise": "Règlement de la totalité",
  "SKU Details": "détails du numéro de référence",
  "Commodity Price": "Détails de l'article",
  "Set as default shipping address": "Prix ​​des marchandises",
  "Reset": "Définir comme adresse de livraison par défaut",
  "Confirm": "Redémarrage",
  "Subscribe to our newsletter": "Confirmer",
  "Promotions, new products and sales. Directly to your inbox.": "Abonnez-vous à notre newsletter",
  "Your email": "Promotions, nouveaux produits et soldes. Directement dans votre boîte de réception.",
  "Subscribe": "Votre email",
  "Company Information": "Registre",
  "OUR SOCIAL MEDIA": "Informations sur la société",
  "PRIVACY STATEMENT": "NOS RÉSEAUX SOCIAUX",
  "Terms and Conditions": "DÉCLARATION DE CONFIDENTIALITÉ",
  "Sure": "Termes et conditions",
  "My Profile": "mon profil",
  "Login/Register": "S'identifier S'enregistrer",
  "Logout": "sortir",
  "Warning Tips": "Conseils d'avertissement",
  "Warning Tips": "Conseils d'avertissement",
  "There are currently no products under this category": "Il n'y a actuellement aucun produit dans cette catégorie",
  "Maximum recommended size 200px * 200px, image can be adaptive": "Taille maximale recommandée 200 px * 200 px, l'image peut être personnalisable",
  "add image": "Ajouter une image",
  "change": "Changer",
  "delete": "Supprimer",
  "Edit Product": "Modifier le produit",
  "Please contact the supplier to improve the external number of the webmaster": "Contactez le fournisseur pour mettre à niveau le numéro de webmaster externe.",
  "Back": "pour retourner",
  "Please contact the supplier to improve the external number of the webmaster": "Contactez le fournisseur pour mettre à niveau le numéro de webmaster externe.",
  "Black": "Noir",
  "There are currently no products under this category": "Il n'y a actuellement aucun produit dans cette catégorie",
  "Logo": "Logo",
  "You can find the recent quotationsand check offers": "Vous pouvez retrouver des devis récents et consulter des offres.",
  "Maximum recommended size 200px * 200px, image can be adapted": "Taille maximale recommandée 200px * 200px, l'image peut être adaptée",
  "Add image": "Ajouter une image",
  "Change": "Changer",
  "Delete": "Supprimer",
  "Change image": "changer l'image",
  "Shopify tags": "Tags",
  "New Tag": "Nouvelle étiquette",
  "Shopify type": "J'aime Shopify",
  "Loading": "Mise en charge",
  "Compare Price": "Comparer le prix",
  "Cost Price": "Juste prix",
  "Product Cost": "Coût du produit",
  "Cancel": "Annuler",
  "incorrect input": "Entrée incorrecte",
  "Tag name": "Nom de la balise",
  "Distributor commission withdrawal": "Retrait de la commission du distributeur",
  "Withdrawal method": "méthode de retrait",
  "Withdrawal Amount": "Montant de retrait",
  "Please enter the commission withdrawal amount": "Veuillez saisir le montant du retrait de commission",
  "Remark": "Observation",
  "Please enter content": "Veuillez saisir le contenu",
  "Save": "Économiser",
  "Close": "Fermer",
  "balance": "équilibre",
  "offline": "débranché",
  "Withdrawal list": "Liste de retrait",
  "accountPayee": "Bénéficiaire du compte",
  "Withdrawal method": "Méthode de retrait",
  "Approval Status": "Statut approuvé",
  "Withdrawal Amount": "Montant de retrait",
  "reject": "Déclin",
  "Customer Name": "Nom du client",
  "agree": "Arriver à un accord",
  "please enter": "Veuillez insérer",
  "application time": "Temps de candidature",
  "start": "Commencer",
  "end": "Fin",
  "to": "Pour",
  "Selected stores": "Magasins sélectionnés",
  "Please select the Shopify stores": "Veuillez sélectionner les magasins Shopify",
  "Shopify": "acheter",
  "Woocommerce": "WooCommerce",
  "Lazada": "Laçage",
  "Shopee": "boutique",
  "Action": "Action",
  "Shopify store": "Boutique Shopify",
  "Change All": "changez tout",
  "Multiply Price": "Multiplier le prix",
  "Add Price": "Ajouter un prix",
  "Wayfair": "manière équitable",
  "Open in Web": "Ouvrir dans le navigateur",
  "Your price": "Ton prix",
  "Push to Store": "Envoyer au magasin",
  "Add Cost Range": "Ajouter une fourchette de coûts",
  "Rest of the price ranges": "Reste des gammes de prix",
  "Price": "Prix",
  "Plus": "Plus loin",
  "Failed reason": "Raison de l'échec",
  "Multiply": "Multiplier",
  "Fixed Price": "Prix ​​fixe",
  "You can set a specific cent value for your price or comparison": "Vous pouvez définir une valeur en centimes spécifique pour votre prix ou votre prix de comparaison.",
  "price.": "Allouer des centimes",
  "Assign Cent": "Attribuer une comparaison en centimes",
  "Assign compare at Cent": "Attribuer une comparaison en centimes",
  "According to store currency": "En fonction de la devise du magasin.",
  "Apply for products in Import List": "Appliquer aux produits de la liste d'importation",
  "Add New Tags": "Ajouter de nouvelles balises",
  "Are you sure to delete it??": "Es-tu sûr de vouloir le supprimer?",
  "Please enter the New tag": "Veuillez insérer une nouvelle balise",
  "Are you sure you want to push?": "Êtes-vous sûr de vouloir envoyer?",
  "Do you want to delete this task? Tips": "Voulez-vous supprimer cette tâche? Conseils",
  "success": "succès",
  "error": "erreur",
  "The update has started, it will take a while to finish.": "La mise à jour a commencé, elle prendra un certain temps pour se terminer.",
  "save successfully": "Enregistré avec succès",
  "Do you want these?": "Tu veux ça ?",
  "product(s)?": "produits)?",
  "Delete success": "Suppression réussie",
  "Would you like to export ?": "Vous souhaitez exporter ?",
  "Export success": "Exportation réussie",
  "Please select the products": "Veuillez sélectionner des produits",
  "URL required": "URL requise",
  "Unit Cost": "Coût unitaire",
  "Import success": "Importation réussie",
  "Please select the tags": "Veuillez sélectionner les balises",
  "Tags added successfully": "Balises ajoutées avec succès",
  "You can import and manage your AliExpress / Amazon products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products.": "Vous pouvez importer et gérer vos produits AliExpress/Amazon ici avant de les publier sur votre boutique Shopify. Vous pouvez répertorier un produit dans différents magasins et ajouter plusieurs balises à chaque produit.",
  "Import Aliexpress / Amazon product URL here and click": "Importez l'URL du produit Aliexpress/Amazon ici et cliquez sur",
  "Product Name": "Nom du produit",
  "Product SKU": "UGS du produit",
  "Shipping to": "Envoyer à",
  "Shipping fee": "Frais d'envoi",
  "Add to List": "Ajouter à la liste",
  "VAT information": "Informations TVA",
  "Not purchased": "Non acheté",
  "you can click": "tu peux cliquer",
  "for VAT information": "pour plus d'informations sur la TVA",
  "Cost per unit": "Coût par unité",
  "Clear selection": "Effacer la sélection",
  "Total Quantity selected": "Quantité totale sélectionnée",
  "Total Quantity": "Montant total",
  "You can find the recent quotations and check offers": "Vous pouvez retrouver des devis récents et consulter des offres.",
  "Waiting for vendors respond": "En attente de réponse des fournisseurs",
  "Vendors responding quotations": "Citations des fournisseurs ayant répondu",
  "Archived quotations": "Citations archivées",
  "Fulfilled": "Servi",
  "Unfulfilled": "Ne sont pas conformes",
  "Partially Fulfilled": "Partiellement réalisé",
  "Refunded": "Remboursé",
  "Today": "Aujourd'hui",
  "Last 7 days": "Les 7 derniers jours",
  "Last 30 days": "les 30 derniers jours",
  "Last 90 days": "90 derniers jours",
  "Last 12 months": "les 12 derniers mois",
  "Custom": "Coutume",
  "Order number (ascending)": "Numéro de commande (croissant)",
  "Order number (descending)": "Numéro de commande (décroissant)",
  "Order number": "Numéro de commande",
  "Date (oldest first)": "Date (le plus ancien en premier)",
  "Date (newest first)": "Date (le plus récent en premier)",
  "Partially refunded": "Partiellement rétabli",
  "orders selected": "commandes sélectionnées",
  "orders selected, uncheck": "commandes sélectionnées, décochez",
  "orders selected, select all": "commandes sélectionnées, tout sélectionner",
  "Request Quotation": "Demande de cotisation",
  "orders": "demandes",
  "Only orders in": "Seules les commandes en",
  "Paid": "Payé",
  "No order selected": "Aucune commande n'est sélectionnée",
  "Create quotation request": "Créer une demande de devis",
  "can": "il peut",
  "Has been copied to the clipboard": "Il a été copié dans le presse-papiers.",
  "Copy to clipboard failed, please copy manually": "Impossible de copier dans le presse-papiers, copiez-le manuellement",
  "waiting for responding": "J'attends une réponse",
  "Cancelled": "Annulé",
  "Confirmed": "Confirmé",
  "ID Range": "plage d'identification",
  "Order Includes": "La commande comprend",
  "View offers": "Voir les offres",
  "Cancel offers": "Annuler les offres",
  "Check offers": "Consulter les offres",
  "View offer detail": "Voir les détails de l'offre",
  "Fulfillment": "Service",
  "Order": "Commande",
  "My Store": "Mon magasin",
  "Date": "Date",
  "Customer": "Client",
  "Total": "Total",
  "Payment": "Payer",
  "Allocate all to vendor": "Attribuez tout au fournisseur.",
  "Fulfillment": "Service",
  "Allocate": "Localiser",
  "button of the page,you can also allocate items from the same SPU to the same vendor": "bouton de page, vous pouvez également attribuer des articles du même SPU au même fournisseur",
  "Items": "ITENS",
  "Product images": "Images du produit",
  "Details": "Détails",
  "Expected price (US$)": "Prix ​​attendu (États-Unis)",
  "Remark": "Observation",
  "Vendors respond": "Les fournisseurs répondent",
  "Status": "État",
  "Purchase Order Reconfirm": "Reconfirmer le bon de commande",
  "Vendor name:": "Nom du fournisseur:",
  "AilExpress shop：": "Boutique AilExpress:",
  "Invite Vendor": "Inviter un fournisseur",
  "Invite Link:": "Lien d'invitation :",
  "Bonus Detail": "Détails des bonus",
  "Amount($)": "Valeur ()",
  "Product name": "Nom du produit",
  "Order Quantity": "quantité commandée",
  "Commission": "Commission",
  "Enter order number": "Entrez le numéro de commande",
  "Product information": "Information produit",
  "Total commission": "Commission totale",
  "Edit Product": "Modifier le produit",
  "Shopify vendor": "Fournisseur Shopify",
  "What is your store name?": "Quel est le nom de votre magasin ?",
  "What is your Email?": "Quel est votre email ?",
  "Cancel purchase order": "Annuler le bon de commande",
  "Use this button to update your Order List if missing orders.The process may take a while to finish.": "Utilisez ce bouton pour mettre à jour votre liste de commandes s'il manque des commandes. Le processus peut prendre un certain temps.",
  "Offered Orders": "Commandes proposées",
  "Offered Products": "Produits proposés",
  "Offer price": "Prix ​​de l'offre",
  "View offer detail": "Voir les détails de l'offre",
  "Discard": "Éliminer",
  "Hold down the slider and drag to the right": "Appuyez et maintenez le curseur et faites-le glisser vers la droite",
  "In order to keep you informed of the update of the order, we suggest you verify the email": "Pour rester informé des mises à jour des commandes, nous vous suggérons de vérifier votre courrier électronique",
  "orders can be process, what do you want to do now?": "Les commandes peuvent être traitées, que souhaitez-vous faire maintenant ?",
  "Need new quotation for my orders": "J'ai besoin d'un nouveau devis pour mes commandes.",
  "Over 30000+ vendors waiting for offer you price": "Plus de 30 000 fournisseurs en attente de vous proposer un prix",
  "Allocate my exist vendors": "Attribuer à mes fournisseurs existants",
  "Just check my orders": "Vérifiez simplement mes commandes",
  "If you already have cooperative vendors, you can allocate orders to them": "Si vous avez déjà des fournisseurs coopératifs, vous pouvez leur attribuer des commandes.",
  "How to process your orders?": "Comment traiter vos commandes ?",
  "Please select at least one order and quote for your order": "Sélectionnez au moins une commande et demandez un devis pour votre commande",
  "Confirm this Offer": "Confirmez cette offre",
  "All Orders": "Tous les ordres",
  "All Products": "Tous les produits",
  "Add Tracking": "Ajouter un suivi",
  "Yes, I want to CANCEL this offer": "Oui, je souhaite ANNULER cette offre",
  "Cancel the success": "Annuler avec succès",
  "Track number": "Numéro de suivi",
  "Please input the tracking number": "Veuillez entrer le numéro de suivi",
  "Track company": "Entreprise de suivi",
  "Please select logistics company": "Veuillez sélectionner la société de logistique",
  "Your request has been sent": "Votre demande à été envoyé",
  "The receiving address has no logistics method set": "L'adresse de livraison n'a pas de mode logistique défini",
  "The order is below the minimum deliverable weight": "La commande est inférieure au poids minimum pour la livraison.",
  "The order is below the minimum deliverable volume": "La commande est inférieure au volume minimum de livraison.",
  "The order is higher than the maximum deliverable weight": "La commande dépasse le poids maximum de livraison.",
  "The order is higher than the maximum deliverable volume": "La commande dépasse le volume maximum de livraison.",
  "The following product attributes of this order do not meet the attribute requirements of deliverable products": "Les attributs de produit suivants de cette commande ne répondent pas aux exigences des produits livrables",
  "The total weight of the goods in this order does not meet the deliverable requirements": "Le poids total des produits de cette commande ne répond pas aux exigences de livraison.",
  "Volume(cm)": "Volume (cm)",
  "Total volume(cm)": "Volume total (cm)",
  "Attribute": "Attribut",
  "I Know": "Je sais",
  "Total weight": "Poids total",
  "Order Information": "Informations sur la commande",
  "Third party order number": "Numéro de commande tiers",
  "Order Number": "Numéro de commande",
  "Stores": "histoires",
  "Creation time": "Date de creation",
  "Kaieteur Bank Payment": "Paiement bancaire Kaieteur",
  "Waiting for the payment result to be returned.": "En attente du retour du résultat du paiement.",
  "You may need to wait a little while": "Il faudra peut-être attendre un peu",
  "View order": "Voir l'ordre",
  "You have successfully completed your order.": "Vous avez complété avec succès votre commande.",
  "Sorry, we can": "Désolé, nous ne pouvons pas",
  "Pay again": "payer à nouveau",
  "Order No.": "Numéro de commande",
  "Receiving": "Reçu",
  "days": "jours",
  "Shipping days": "Jours d'expédition",
  "Subtotal": "Total",
  "Freight": "Fret",
  "Discount": "Rabais",
  "Exchange": "Échange",
  "Credits": "Crédits",
  "The supplier has opened a credit limit for you. When your account balance is insufficient, you can deduct the credit limit": "Le fournisseur a ouvert une limite de crédit pour vous. Lorsque le solde de votre compte est insuffisant, vous pouvez déduire votre limite de crédit.",
  "Payment method": "Procédé de paiement",
  "There is no alternative method of payment": "Il n'existe pas de méthode de paiement alternative.",
  "Quantity of order": "Quantité commandée",
  "Total amount of goods": "Valeur totale des produits",
  "Tax Amount": "Montant de la taxe",
  "Discounted price of goods": "Prix ​​réduit des produits.",
  "Total freight amount": "Coût total d'expédition",
  "Shop discount": "Remise en magasin",
  "Amount due (including freight)": "Montant dû (frais de port compris)",
  "This document is free of freight": "Ce document n'a pas d'expédition",
  "Submit orders": "Soumettre les commandes",
  "credit&debit card": "carte de crédit et de débit",
  "qr payment": "paiement par QR code",
  "Please select payment method": "Veuillez sélectionner le mode de paiement",
  "Order Management": "Gestion des commandes",
  "Import order": "Ordre d'importation",
  "Your No.": "Toi non.",
  "Export order": "Commande d'exportation",
  "Filter": "Filtre",
  "Clear": "Faire le ménage",
  "Create a quote": "Créer un devis",
  "Quit quotation": "Annuler le devis",
  "Bulk payment": "Paiement groupé",
  "Cancel payment": "Annuler le paiement",
  "Manually synced": "Synchronisé manuellement",
  "Continue Pay": "Continuer à payer",
  "Re quote": "Refaire le devis",
  "Your shop order num": "Votre numéro de commande en magasin",
  "Unable to purchase": "On ne peut pas l'acheter",
  "Products Off shelf": "Produits en rupture de stock",
  "Supply unit price": "Prix ​​unitaire de l'offre",
  "Shop price：": "Prix ​​en magasin :",
  "customer Name": "Nom du client",
  "Not reviewed": "Pas révisé",
  "pass": "Approuver",
  "reject": "Déclin",
  "Please make an offer": "Merci de faire une offre",
  "Removed": "Télécommande",
  "Delete": "Supprimer",
  "Order Number": "Numéro de commande",
  "Unable to purchase": "On ne peut pas l'acheter",
  "Please make an offer": "null Veuillez faire une offre",
  "warehouse": "stockage",
  "address": "ADRESSE",
  "Customer": "Client",
  "Products": "produits",
  "Product": "Produit",
  "Shipping Method": "Mode de livraison",
  "Manually synced": "Synchronisé manuellement",
  "Payment time": "Délai de paiement",
  "Payment amount": "Montant du paiement",
  "inventory": "inventaire",
  "Inventory lock remaining time": "Temps de verrouillage de l'inventaire restant",
  "Order quantity": "Quantité commandée",
  "Cancel payment": "Annuler le paiement",
  "Unable to purchase": "On ne peut pas l'acheter",
  "Please make an offer": "null Veuillez faire une offre",
  "Please make an offer": "Merci de faire une offre",
  "Created Time": "Temps de creation",
  "Shipping address": "Adresse de livraison",
  "Request Fullfill": "Demander un service complet",
  "Shipping Method": "Mode de livraison",
  "Shipping cost": "Coût de l'expédition",
  "pay total": "payer en entier",
  "Select Payment Method": "Sélectionnez le mode de paiement",
  "All transactions are secure and encrypted": "Toutes les transactions sont sécurisées et cryptées.",
  "Pay with": "Payer avec",
  "Please do not close the page during payment": "Veuillez ne pas fermer la page lors du paiement.",
  "Your order has been successfully paid": "Votre commande a été payée avec succès",
  "The selected orders are abnormal and payment cannot be continued": "Les commandes sélectionnées sont anormales et ne peuvent poursuivre le paiement.",
  "Payment can continue after excluding the following goods": "Le paiement peut continuer après la suppression des produits suivants",
  "After removing the off shelf goods, the order will be returned to the": "Après retrait des produits en rupture de stock, la commande sera retournée au",
  "price to be quoted": "prix à citer",
  "Confirm": "Confirmer",
  "Select file": "Choisir le dossier",
  "Click to download": "Cliquez pour télécharger",
  "Order Import Template": "Modèle d'importation de commande",
  "Please fill in the form according to the specification, only xlsx format files are supported": "Remplissez le formulaire selon les spécifications, seuls les fichiers au format xlsx sont pris en charge",
  "The uploaded file cannot exceed 2M, and only the first 99 data are processed": "Le fichier téléchargé ne peut pas dépasser 2 Mo et seules les 99 premières données sont traitées.",
  "submit": "livrer",
  "Quotation to be submitted": "Devis à présenter",
  "Vendor quoted order": "Commande citée par le fournisseur",
  "Payment Not Received": "Paiement non-reçu",
  "Pending payment": "Impayé",
  "Waiting for fulfill": "attendre de remplir",
  "Fulfilled": "Compliment",
  "Completed": "Fini",
  "Logistics synchronization failed": "Échec de la synchronisation logistique",
  "Unable to purchase": "On ne peut pas l'acheter",
  "Payment to be completed": "Paiement à compléter",
  "Waiting for fulfill": "attendre de remplir",
  "Quit quotation": "Supprimer la liste",
  "danger": "danger",
  "Delivery Address": "Addresse de livraison",
  "My stores": "mes magasins",
  "warning": "Avertissement",
  "Vendor Verifying Payment": "Fournisseur vérifiant le paiement",
  "Payment result to be returned": "Résultat du paiement à restituer",
  "Sure to delete this item?": "Êtes-vous sûr de supprimer cet élément?",
  "OK": "D'ACCORD",
  "cancel": "Annuler",
  "Delete Successful!": "Suppression réussie!",
  "Canceled": "Annulé",
  "Please select an order": "Veuillez sélectionner une commande",
  "Binding succeeded!": "Le syndicat l'a fait !",
  "There is no warehouse, please go to add！": "Il n'y a pas d'entrepôt, veuillez aller ajouter",
  "Please choose file": "Veuillez choisir le fichier",
  "file cannot over 2MB": "Le fichier ne peut pas dépasser 2 Mo",
  "Create Success": "Créer du succès",
  "No goods need to be eliminated": "Pas besoin de retirer de marchandise.",
  "Please select an order firs": "Veuillez sélectionner une première commande",
  "Successfully": "Avec succès",
  "No logistics": "Sans logistique",
  "Are you sure to abandon payment for the selected order? After abandoning, the order will disappear from the list, which cannot be restored, please operate with caution": "Êtes-vous sûr d'abandonner le paiement de la commande sélectionnée? Après abandon, la commande disparaîtra de la liste et ne pourra plus être restaurée. Opérez avec prudence.",
  "Are you sure to initiate a quotation for the selected order?": "Etes-vous sûr de démarrer un devis pour la commande sélectionnée?",
  "Are you sure to abandon the quotation for the selected order? After giving up, it cannot be restored, please operate with caution": "Etes-vous sûr d'abandonner le devis pour la commande sélectionnée? Après avoir abandonné, il ne peut pas être restauré, veuillez fonctionner avec prudence",
  "Payment has not arrived": "Le paiement n'est pas arrivé",
  "Payment to be completed": "Paiement à compléter",
  "Waiting for fulfillment": "Attendez que cela se réalise",
  "Logistics synchronization failed": "Échec de la synchronisation logistique",
  "Unable to purchase": "On ne peut pas l'acheter",
  "Order ID": "Numéro de demande",
  "SortBy": "Triste",
  "Date Ranges": "Plages de dates",
  "Fulfillment Status": "Statut de conformité",
  "Export to": "Exporter vers",
  "Selected": "Choisi",
  "Some orders had been fulfilled or refunded": "Certaines commandes ont été honorées ou remboursées.",
  "Export orders": "Commandes d'exportation",
  "Attribute Value": "Valeur d'attribut",
  "Abnormal reason": "raison anormale",
  "Date type": "Type de date",
  "Download tracking information": "Télécharger les informations de suivi",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Vos commandes sont en cours d'export</strong><br/>Votre export sera livré par email à",
  "Depending on how many Orders you’re exporting, this could take some time.": "Selon le nombre de commandes que vous exportez, cela peut prendre un certain temps.",
  "<strong>Your Orders are exporting</strong><br/>Your export will be delivered by email to: . <br/>Depending on how many Orders you’re exporting, this could take some time.": "<strong>Vos ​​commandes sont en cours d'export</strong><br/>Votre export sera livré par email à : . <br/>En fonction du nombre de commandes que vous exportez, cela peut entraîner",
  "Notice": "Avertissement",
  "You have": "Tu as",
  "purchase order(s) need your reconfirm,": "le ou les bons de commande doivent être reconfirmés,",
  "click here": "Cliquez ici",
  "to view details.": "pour voir les détails.",
  "Vendor shipped": "Fournisseur envoyé",
  "unfulfilled / partially fulfilled)": "non atteint/partiellement atteint)",
  "But": "Mais",
  "You may download these tracking information and fulfilled these orders in your Shopify store manualy.": "Vous pouvez télécharger ces informations de suivi et exécuter ces commandes manuellement depuis votre boutique Shopify.",
  "Download ALL tracking information": "Téléchargez TOUTES les informations de suivi",
  "sync failed": "erreur de synchronisation",
  "Reconfirm": "Reconfirmer",
  "View Details": "Voir les détails",
  "Change another vendor": "Changer de fournisseur",
  "Export": "Exporter",
  "Cancel this order": "Annuler cette commande",
  "NOT Joined": "NON ADHÉRÉ",
  "Amount Due": "Montant dû",
  "Not set up": "Pas configuré",
  "View Details": "Voir les détails",
  "Send to vendor": "Envoyer au fournisseur",
  "Share by link": "Partager via un lien",
  "Share link": "Lien de partage",
  "Copy Link": "Copier le lien",
  "Title": "Qualification",
  "Desc": "Description",
  "Share": "Partager",
  "Scan share to WeChat": "Scanner le partage sur WeChat",
  "Includes": "Comprend",
  "Shopify orders": "Commandes Shopify",
  "products": "produits",
  "invalid": "invalide",
  "Income": "Performance",
  "Income will only visible to you": "Les revenus ne seront visibles que par vous",
  "Allow vendor to process these orders": "Autoriser le fournisseur à traiter ces commandes.",
  "Do you want to cancel this order?": "Voulez-vous annuler cette commande?",
  "Don": "Enfiler",
  "Shopify orders in the": "Commandes Shopify dans",
  "Discard": "Élimination",
  "Change purchase order to another vendor": "Modifier le bon de commande chez un autre fournisseur",
  "Current vendor:": "Fournisseur actuel:",
  "Change to new vendor": "Passer à un nouveau fournisseur",
  "All my vendors": "Tous mes fournisseurs",
  "Also allocate the products in this purchase order to the new supplier": "Affectez également les produits de ce bon de commande au nouveau fournisseur.",
  "Order created": "Commande créée",
  "Order paid": "Commande payée",
  "Login to your": "Connectez-vous à votre",
  "Order confirmed": "Commande confirmée",
  "Your request is been submitted, please wait one moment.": "Votre commande a été envoyée, veuillez patienter un instant.",
  "Copy to clipboard failed, please copy manually": "Erreur de copie dans le presse-papiers, copiez-le manuellement",
  "The update process has started, it will take a while to finish.": "Le processus de mise à jour a commencé, il faudra un certain temps pour le terminer.",
  "Update Order": "Mise à jour de la commande",
  "You have": "Tu as",
  "purchase orders": "Acheter en ligne",
  "NEED to finish payment": "VOUS DEVEZ TERMINER LE PAIEMENT",
  "Waiting for allocated": "Attendre d'être assigné",
  "Waiting for sourcing": "En attente d'approvisionnement",
  "Orders processing": "Commande en traitement",
  "Abnormal order": "ordre anormal",
  "Abnormal": "Ce n'est pas normal",
  "All orders": "Tous les ordres",
  "Pending": "Boucle d'oreille",
  "Please select": "Veuillez sélectionner",
  "Quote for Orders": "Devis pour les commandes",
  "Quote for Product": "Devis produit",
  "Recent Quotations": "Citations récentes",
  "orders matching your search": "commandes correspondant à votre recherche",
  "Export order": "Commande d'exportation",
  "Email": "Courrier électronique",
  "Change": "Changer",
  "Get captcha": "Obtenir un captcha",
  "try again in": "réessayez",
  "seconds": "secondes",
  "Please fill in the correct Email address": "Veuillez remplir la bonne adresse e-mail",
  "User Email modified successfully": "L'adresse e-mail de l'utilisateur a été modifiée avec succès",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Vos commandes sont en cours d'export</strong><br/>Votre export sera livré par email à",
  "<br/>Depending on how many Orders you’re exporting, this could take some time": "<br/>En fonction du nombre de commandes que vous exportez, cela peut prendre un certain temps.",
  "Show cancelled orders": "Afficher les commandes annulées",
  "Show all valid orders": "Afficher toutes les commandes valides",
  "unfulfilled / partially fulfilled": "non atteint/partiellement atteint",
  "Download NOT FULFILLED tracking information": "Informations de suivi NON REMPLIES",
  "sync failed": "erreur de synchronisation",
  "Change another vendor": "Changer de fournisseur",
  "Invite vendor to process": "Inviter le fournisseur à traiter",
  "More Action": "Plus d'action",
  "Selected saved! Now you can Check the purchase orders": "Sélection enregistrée! Vous pouvez désormais consulter les bons de commande",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "Après confirmation du paiement, nous informerons le fournisseur par email. Vous pouvez également informer le fournisseur de manière proactive pour accélérer la livraison.",
  "Have you finished the payment?": "Avez-vous terminé le paiement ?",
  "Confirm the payment": "Confirmer le paiement",
  "The reason must be entered": "La raison doit être indiquée",
  "In this tab, we will only filter your": "Dans cet onglet, nous filtrerons uniquement votre",
  "Orders which you can allocate to your vendor to process.": "Commandes que vous pouvez confier à votre fournisseur pour qu'il les traite.",
  "Unfulfilled": "Non réalisé",
  "Shop name:": "Nom du magasin:",
  "Partially fulfilled": "Partiellement réalisé",
  "Fulfillment Status": "Statut de conformité",
  "Allocate all to vendor": "Attribuez tout au fournisseur.",
  "purchased items are delivered": "les articles achetés sont livrés",
  "Select a vendor": "Sélectionnez un fournisseur",
  "Product has been allocated": "Le produit a été attribué.",
  "Vendor name / contact / shop name etc.": "Nom du fournisseur/contact/nom du magasin, etc.",
  "Here are some other products seems can been allocated to the vendor too, do you want to do it now?": "Voici quelques autres produits qui semblent également pouvoir être attribués au fournisseur. Voulez-vous le faire maintenant ?",
  "Selected products": "Produits sélectionnés",
  "Allocate NOW": "Attribuer MAINTENANT",
  "No thanks": "Non merci",
  "Please select at least one order": "Sélectionnez au moins une commande",
  "Create quotation request?": "Créer une demande de devis ?",
  "Copy to clipboard failed, please copy manually": "Erreur de copie dans le presse-papiers, copiez-le manuellement",
  "Order ID From:": "Numéro de commande pour:",
  "Order ID To:": "Numéro de commande pour:",
  "You": "Toi",
  "orders to": "commandes pour",
  "vendors": "les vendeurs",
  "responded": "il a répondu",
  "You can choose the payment method you can accept, we will submit your": "Vous pouvez choisir le mode de paiement que vous pouvez accepter,",
  "orders and options to your vendors": "commandes et options pour vos fournisseurs",
  "Warm tips": "Conseils chaleureux",
  "orders NOW": "commande maintenant",
  "Place": "Lieu",
  "You have set the following products to be allocated to the vendors": "Vous avez défini les produits suivants à affecter aux fournisseurs",
  "only once. Therefore, the following items will need to be": "Juste une fois. Par conséquent, les éléments suivants doivent être",
  "re-allocated after the orders are generated again.": "réaffecté après la nouvelle génération des commandes.",
  "By AliExpress VIP link": "Par lien VIP Aliexpress",
  "Please enter the single number range": "Veuillez saisir une plage de numéros unique",
  "The starting order number must be less than the ending order number": "Le numéro de commande initial doit être inférieur au numéro de commande final.",
  "Product title": "Titre du produit",
  "Expected price": "Prix ​​attendu",
  "Remove entrusted vendor": "Supprimer le fournisseur de confiance",
  "Reply price": "Prix ​​de réponse",
  "Reply link": "Lien de réponse",
  "published": "publié",
  "Yesterday": "Hier",
  "Select": "Sélectionner",
  "My support": "Mon support",
  "Connect": "Se connecter",
  "Reauthorize": "Autoriser à nouveau",
  "Choose a vendor": "Choisissez un fournisseur",
  "Are you sure you want to remove entrusted vendor?": "Êtes-vous sûr de vouloir supprimer le fournisseur de confiance?",
  "You have successfully hosted the": "Vous avez hébergé avec succès le",
  "After the successful hosting, your supplier will take the initiative to quote for your pending orders!": "Après un hébergement réussi, votre fournisseur prendra l'initiative de proposer vos commandes en cours !",
  "Are you sure you want to cancel the hosting store": "Êtes-vous sûr de vouloir annuler le magasin d'hébergement?",
  "after you close the hosting, the supplier will not be able to actively quote your order!": "Une fois l’hébergement fermé, le fournisseur ne pourra plus proposer activement votre commande!",
  "If you do not check the relevant permissions, it will affect the use of related functions, please operate with caution": "Si vous ne vérifiez pas les autorisations pertinentes, cela affectera l'utilisation des fonctions associées, veuillez utiliser avec prudence",
  "vendor is authorizing the store on BCNDropshipping App, please be noted.": "Le fournisseur autorise la boutique dans l'application BCNDropshipping, veuillez noter.",
  "Failure reason": "Raison de l'échec",
  "Please enter price": "Veuillez entrer le prix",
  "Store products": "Magasin de produits",
  "Publish": "publier",
  "Query": "Consultation",
  "Reset": "CV",
  "Price units default to": "Unités de prix standard pour",
  "Please upload pictures": "Merci d'envoyer des photos",
  "Please enter title": "Veuillez entrer le titre",
  "Search Products": "Recherche de produits",
  "Please enter": "Veuillez insérer",
  "State": "Propriété d'État",
  "Pending": "Boucle d'oreille",
  "Waiting": "attendre à",
  "Processing": "Traitement",
  "Failed": "Refusé",
  "All": "Tout",
  "Description:": "Description:",
  "Edit": "Éditer",
  "ADD": "AJOUTER",
  "Add Vendor": "Ajouter un fournisseur",
  "Edit Vendor": "Modifier le fournisseur",
  "Destination Country:": "Pays de destination:",
  "My sourcing products": "Mon offre de produits",
  "Vendor information": "Informations sur le fournisseur",
  "Vendor Contact": "Contact Fournisseur",
  "Bonus Balance（": "Solde bonus",
  "Sales statistics": "Statistiques de ventes",
  "Please enter description": "Veuillez saisir une description",
  "Please enter IOSS ID": "Veuillez saisir l'identifiant IOSS",
  "Please select destination country": "Sélectionnez le pays de destination",
  "Please select at least 1 destination country.": "Sélectionnez au moins un pays de destination.",
  "Please enter an IOSS ID with 12 alphanumeric characters.": "Saisissez un identifiant IOSS composé de 12 caractères alphanumériques.",
  "After saving, your IOSS will be updated to the corresponding order. Are you sure to save?": "Après l'enregistrement, votre IOSS sera mis à jour selon la commande correspondante. Etes-vous sûr que vous allez enregistrer ?",
  "Dashboard": "Panneau",
  "Settings": "paramètres",
  "According to the new European VAT e-commerce rules, declaration without lOSS will result in a delay in customs clearance and the recipient will be required to pay VAT, as the parcel will be detained when contact of the recipient is incorrect.": "Selon les nouvelles règles européennes de TVA sur le commerce électronique, une déclaration sans perte entraînera un retard dans le dédouanement et le destinataire devra payer la TVA, puisque le colis sera retenu lorsque les coordonnées du destinataire sont incorrectes.",
  "IOSS Option": "Option IOSS",
  "No IOSS": "Sans IOSS",
  "The amount of the orders (shipping cost included) will be declared and recipients will be required by the customs to pay VAT when parcels arrive in the destination countries of EU": "La valeur des commandes sera déclarée (y compris les frais d'expédition) et les douanes exigeront que les destinataires paient la TVA lorsque les commandes arrivent dans les pays de destination de l'UE.",
  "Please enter correct and valid IOSS IDs, which should be linked to corresponding destination countries. IOSS ID will not be applied if the destination country is not linked to a correct IOSS ID": "Saisissez les identifiants IOSS corrects et valides, qui doivent être liés aux pays de destination correspondants. L'identifiant IOSS ne sera pas appliqué si le pays de destination n'est pas lié à un identifiant IOSS correct.",
  "My IOSS Settings": "Ma configuration IOSS",
  "Declare with my own IOSS": "Déclarer avec mon propre IOSS",
  "Linked Countries": "Pays liés",
  "Add IOSSou sure to delete the IO": "Ajoutez IOS ou assurez-vous d'exclure IO",
  "Unlinked Countries:": "Pays sans rapport:",
  "Are you sure to delete the IOSS?": "Êtes-vous sûr de supprimer IOSS?",
  "More categories": "Plus de catégories",
  "Excluding VAT amount": "Montant hors TVA",
  "Vat Amount": "Valeur TVA",
  "Amount including VAT": "Montant TTC",
  "AliExpress store information": "Informations sur la boutique Aliexpress",
  "Detailed seller ratings": "Avis détaillés du vendeur",
  "Recent Products": "Produits recents",
  "Store No.": "N’en stockez aucun.",
  "This store has been open since": "Ce magasin est ouvert depuis",
  "Positive feedback": "Commentaire positif",
  "Business License": "Licence commerciale",
  "Item as Described": "Article conforme à la description",
  "Above": "Au-dessus de",
  "Below": "Ci-dessous",
  "Average": "Moyenne",
  "Shipping Speed": "Vitesse de livraison",
  "Detailed Seller Ratings information is unavailable when there": "Les informations détaillées sur les évaluations du vendeur ne sont pas disponibles lorsque",
  "Add vendors success": "Ajoutez du succès aux fournisseurs",
  "Add a new vendor": "Ajouter un nouveau fournisseur",
  "My Vendors": "Mes fournisseurs",
  "By Vendor Name": "Par nom de fournisseur",
  "By AliExpress Url": "Par URL Aliexpress",
  "Please enter your vendor": "Veuillez entrer votre fournisseur",
  "Vendor": "Vendeur",
  "Vendor": "Vendeur",
  "How to get the vendor": "Comment obtenir le fournisseur",
  "Transaction Flow Line": "Ligne de flux de transactions",
  "Recharge Time": "Temps de recharge",
  "Are you sure you want to delete it?": "Es-tu sûr de vouloir le supprimer?",
  "Customer name required": "Nom du client requis",
  "customer successfully edited": "client modifié avec succès",
  "customer successfully added": "client ajouté avec succès",
  "Address Management": "Gestion des adresses",
  "Add New Address": "Ajouter une nouvelle adresse",
  "Default Shipping Address": "adresse de livraison par défaut",
  "First name": "Prénom",
  "Last name": "Le nom",
  "Phone": "Téléphone",
  "Push": "Pousser",
  "Address": "ADRESSE",
  "City": "Ville",
  "Postcode": "Code postal",
  "Province": "Province",
  "Country": "arrière-pays",
  "Edit": "Éditer",
  "Delete": "Éteindre",
  "No address": "Sans direction",
  "Do you want to delete?": "Voulez-vous supprimer ?",
  "delete successfully": "supprimer avec succès",
  "My Allocated Products": "Mes produits attribués",
  "save successfully": "sauvegarde réussie",
  "My Allocated Product": "Mon produit attribué",
  "Batch Re-allocate": "Réallocation des lots",
  "Batch Un-allocate": "Remplacement de l'allocation de lots",
  "All my stores": "Tous mes magasins",
  "UN-allocate Success": "Réussite de la mission à l'ONU",
  "Home": "foyer",
  "My Profile": "mon profil",
  "Logout": "sortir",
  "LOGIN": "ACCÉDER",
  "Attach image file": "Joindre un fichier image",
  "Browse": "Naviguer",
  "Customer Support Email": "E-mail du service client",
  "Send your request": "Soumettez votre commande",
  "Powered by BCN": "Développé par BCN",
  "Privacy policy": "Politique de confidentialité",
  "Terms of use": "Conditions d'utilisation",
  "Refund Policy": "Politique de remboursement",
  "Contact Us": "Contactez-nous",
  "Getting Started": "Premiers pas",
  "Main Features": "Principales caractéristiques",
  "FAQs": "questions fréquentes",
  "This file format is not supported": "Ce format de fichier n'est pas pris en charge",
  "The file size can not over 2MB": "La taille du fichier ne peut pas dépasser 2 Mo.",
  "Please input your message": "Veuillez entrer votre message",
  "BCNDropshipping - Easy Dropshipping": "BCNDropshipping – La livraison directe simplifiée",
  "Please enter Shopify store name": "Entrez le nom de votre boutique Shopify",
  "The question must not be empty": "La question ne doit pas être vide.",
  "The email must not be empty": "L'email ne doit pas être vide.",
  "Shopify store name": "Nom du magasin Shopify",
  "Current Unit Cost": "Coût unitaire actuel",
  "Send Success,We will contact you soon.": "Soumettez avec succès, nous vous contacterons bientôt.",
  "Welcome to": "Bienvenue à",
  "Please sign in or register an account to start the chat.": "Connectez-vous ou créez un compte pour commencer à discuter.",
  "All Items": "tous les articles",
  "back": "derrière",
  "Check All": "vérifie tout",
  "Digital Themrmometer Hygrometer Living Ro Digital Themrmometer Hygrometer Living Ro": "Thermomètre numérique de haute qualité Ro hygromètre en direct hygromètre numérique thermomètre en direct Ro",
  "Bulk Purchase": "Achète en masse",
  "Amount": "Montant",
  "Do you want to delete selected items?": "Voulez-vous supprimer les éléments sélectionnés?",
  "Product list page": "Page de liste de produits",
  "Bottom": "Ci-dessous",
  "Top": "Au-dessus de",
  "Hi, welcome to": "Bonjour, bienvenue à",
  "Welcome to ()! Please sign in or register an account to": "Bienvenue à ()! Veuillez vous connecter ou créer un compte pour",
  " start the chat.": "commencez la conversation.",
  "Sign in": "Se connecter",
  "Register": "Enregistrer",
  "Recall": "rappeler",
  "wallet": "mallette",
  "balance": "Équilibre",
  "system notification": "notification du système",
  "This month Orders": "Les commandes de ce mois",
  "This month Revenue": "La recette du mois",
  "This month Cost": "Ce mois-ci, ça coûte",
  "Statistics": "Statistiques",
  "Reference": "Référence",
  "Show More": "Montre plus",
  "Quick Summary": "Résumé rapide",
  "My Products": "Mes produits",
  "Waiting to place order": "En attente de passer la commande",
  "Waiting for tracking code": "En attente du code de suivi",
  "Stores": "histoires",
  "Trade name": "Nom commercial",
  "Authorized": "Autorisé",
  "Purchase price:": "Prix ​​d'achat:",
  "NOT SET": "NON DÉFINI",
  "Start date": "Date de début",
  "End date": "Date finale",
  "Expected Price": "Prix ​​attendu",
  "Product link": "Connexion du produit",
  "no product": "pas de produit",
  "Dispatched Items": "Articles expédiés",
  "Valid Invoices": "Factures valides",
  "Valid Amount Due": "Montant valide dû",
  "Order’s items that you can dispatch to vendors": "Commandez des articles que vous pourrez ensuite envoyer aux fournisseurs",
  "No Picture": "Sans image",
  "No Data": "Aucune donnée",
  "Dispatch order’s items to vendors": "Envoyez les articles de commande aux fournisseurs.",
  "Share by APP": "Partager via l'application",
  "Your vendor": "Votre fournisseur",
  "is inviting you to allocate your Orders": "vous invite à attribuer vos commandes",
  "Your store name": "Le nom de votre magasin",
  "Dispatch order method": "Mode d'expédition de la commande",
  "All my unfulfilled / partially fulfilled orders": "Toutes mes commandes non exécutées/partiellement exécutées",
  "Cycle time": "Temps d'un cycle",
  "Every 1 day": "Chaque 1 jour",
  "You can modify your order dispatch rules at any time in the menu": "Vous pouvez à tout moment modifier les règles d'expédition de votre commande dans le menu",
  "Remind later": "je me souviens plus tard",
  "I agree": "Je suis d'accord",
  "Shopify order": "Commande Shopify",
  "Dispatch to": "Envoyer à",
  "vendor(s)": "Fournisseurs)",
  "Dispatch in the Next Cycle": "Expédition dans le prochain cycle",
  "Dispatch Immediately": "Envoyer immédiatement",
  "You can modify your store settings at any time in the menu": "Vous pouvez modifier les paramètres de votre boutique à tout moment dans le menu",
  "Newbie": "Débutant",
  "guide": "guide",
  "Complete personal information": "informations personnelles complètes",
  "Authorize to bind the store": "Publier un push produit",
  "Publish the push product": "Publier un push produit",
  "Start pulling orders": "Commencez à prendre des commandes",
  "Already": "Déjà",
  "enter": "Montez",
  "Add vendors success": "Ajoutez du succès aux fournisseurs",
  "Please select the products": "Veuillez sélectionner des produits",
  "Please select the vendor": "Veuillez sélectionner un fournisseur",
  "Please select the circle period": "Sélectionnez la période du cercle",
  "Copy to clipboard failed, please copy manually": "Erreur de copie dans le presse-papiers, copiez-le manuellement",
  "Are you sure you want to cancel it?": "Es-tu sûre de vouloir annuler?",
  "Disputed order": "ordonnance contestée",
  "Awaiting Response": "J'attends une réponse",
  "Search": "Chercher",
  "Tracking Number": "Numéro de suivi",
  "Dispute Type": "Type de litige",
  "Expected Solution": "Solution attendue",
  "Create Date": "Date de creation",
  "Last week": "La semaine dernière",
  "Last month": "Le mois dernier",
  "OK": "D'ACCORD",
  "Type": "Entrer",
  "Last three months": "les trois derniers mois",
  "The page you are looking for can": "La page que vous recherchez peut",
  "BACK TO HOMEPAGE": "RETOUR À LA PAGE PRINCIPALE",
  "Transform Your eCommerce business Into a Powerhouse": "Transformez votre entreprise de commerce électronique en une centrale électrique",
  "Importify helps people like you to automate their dropshipping business.": "Importify aide les gens comme vous à automatiser leur activité de dropshipping.",
  "Product Research": "Recherche de produit",
  "Are you tired of searching the millions o products on AliExpress for potential winners?": "Vous en avez assez de rechercher des millions de produits sur Aliexpress à la recherche de gagnants potentiels ?",
  "Are you tired of searching the millions o products on AliExpress for potential winners?": "Vous en avez assez de rechercher des millions de produits sur Aliexpress à la recherche de gagnants potentiels ?",
  "One-click import products": "Importez des produits en un clic",
  "Import Product": "produit d'importation",
  "Profit": "Revenu",
  "Import products from 30+ top dropshipping vendors, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart, and many more.": "Importez des produits de plus de 30 fournisseurs de dropshipping, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart et bien d'autres.",
  "Pricing Rules": "Règles de tarification",
  "Tired of calculating your target profit margin? Create pricing rules before importing, quickly adjust prices for sales.": "Fatigué de calculer votre marge bénéficiaire cible? Créez des règles de tarification avant d'importer, ajustez rapidement les prix de vente.",
  "Product Customization": "",
  "Use our chrome extension to modify the title, description, images, variants, pricing of the imported products.": "Utilisez notre extension Chrome pour modifier le titre, la description, les images, les variantes, les prix des produits importés.",
  "Automate your orders": "Automatisez vos commandes",
  "The Importify Chrome Extension allows you to fulfill your orders quickly by placing the customer address automatically on the checkout page.": "L'extension Importify Chrome vous permet de finaliser vos commandes rapidement en plaçant automatiquement l'adresse du client sur la page de paiement.",
  "Easy vendor Change": "Changement de fournisseur facile",
  "Replace your product vendor with a new vendor and connect them to your store with a click.": "Remplacez le fournisseur du produit par un nouveau fournisseur et connectez-le à votre boutique en un clic.",
  "Auto Dispatch Collections": "Collections à répartition automatique",
  "Auto-dispatch products to your Store collections supported on Shopify, WooCommerce, and Jumpseller.": "Insérez automatiquement des produits dans les collections de votre boutique optimisées par Shopify, WooCommerce et Jumpseller.",
  "Split Variants": "Variantes divisées",
  "Variants": "Variantes",
  "Importify gives you the ability to Split variants into Separate products.": "Importify vous donne la possibilité de diviser les variantes en produits distincts.",
  "Connect Existing Products": "Connectez les produits existants",
  "If you have products that are already on a Shopify, Woocommerce or Jumpseller store, you can connect and manage them in Importify.": "Si vous avez des produits qui se trouvent déjà dans une boutique Shopify, Woocommerce ou Jumpseller, vous pouvez les connecter et les gérer dans Importify.",
  "Add to Import List": "Ajouter à la liste d'importation",
  "Add to Cart": "Ajouter au panier",
  "View More": "voir plus",
  "MORE": "PLUS LOIN",
  "Categories": "Catégories",
  "size": "taille",
  "Stock Qty": "Quantité en stock",
  "Total Revenue": "Revenu total",
  "Revenue": "Revenu",
  "Total Cost": "Coût total",
  "Total Orders": "Total des commandes",
  "weight": "Poids",
  "GO": "ALLER",
  "Tag": "Étiquette",
  "Returnable with in": "Retournable avec",
  "No Account?": "Pas de compte?",
  "or Sign in with": "o Connectez-vous avec",
  "Already have an account?": "Avez-vous déjà un compte?",
  "Find Products": "trouver des produits",
  "My Import List": "Ma liste d'importation",
  "Please select a shipping address": "Veuillez sélectionner une adresse de livraison",
  "Please select a shipping method": "Sélectionnez une méthode d'expédition",
  "How to find products? Find products in": "Comment trouver des produits ? Retrouvez les produits sur",
  "Products Market! Dropshipping from Worldwide to Worldwide! :": "Marché de produits ! Expédition directe du monde entier vers le monde entier ! :",
  "Email must be filled in": "L'email doit être complété.",
  "CAPTCHA must be filled in": "Le CAPTCHA doit être complété",
  "New password must be filled in": "Le nouveau mot de passe doit être complété",
  "The new password and confirm password must be the same": "Le nouveau mot de passe et le mot de passe de confirmation doivent être identiques.",
  "The password must contain letters, and numbers, Minimum 8 characters": "Le mot de passe doit contenir des lettres et des chiffres, au moins 8 caractères.",
  "Password reset successful": "Réinitialisation du mot de passe réussie",
  "Password must be filled in": "Le mot de passe doit être complété.",
  "IN ALL CATEGORIES": "DANS TOUTES LES CATÉGORIES",
  "Request Quotation": "Demande de cotisation",
  "More categories": "Plus de catégories",
  "There are no products in this category": "Il n'y a aucun produit dans votre panier",
  "Place Order": "Passer la commande",
  "Choose orders": "Choisissez les commandes",
  "Manage-Addresses": "Gérer les adresses",
  "Default Address": "Adresse par défaut",
  "There is no Shipping method to reach the current address": "Il n'existe aucun mode d'expédition pour atteindre l'adresse actuelle.",
  "Please select address": "Veuillez sélectionner l'adresse",
  "Order Information": "Informations sur la commande",
  "Product Price": "Prix ​​du produit",
  "Choose the products from your Shopify orders to allocated to your vendor": "Choisissez les produits de vos commandes Shopify à attribuer à votre fournisseur",
  "Click the Allocate to vendo button on the bottom of the page.": "Cliquez sur le bouton Allouer pour la vente en bas de la page.",
  "Tips: You can filter your orders here.": "Conseils: Vous pouvez filtrer vos commandes ici.",
  "Choose or create a new vendor.": "Escolha ou crie um novo supplier.",
  "Click the Allocate button of the page,you can also allocate items from the same SPU to the same vendor": "Cliquez sur le bouton Attribuer sur la page; Vous pouvez également affecter des articles de la même SPU au même fournisseur.",
  "Un-allocate selected products": "Annuler l'attribution des produits sélectionnés",
  "Total vendors": "Total des fournisseurs",
  "Total orders": "Total des commandes",
  "Click the": "clique sur le",
  "Allocate to vendor": "Attribuer au fournisseur",
  "button on the bottom of the page.": "bouton en bas de la page.",
  "Total Product": "Produit total",
  "Previous": "Ancien",
  "Next": "Suivant",
  "and Automate": "et automatiser",
  "your entire dropshipping process": "tout votre processus de dropshipping",
  "Place 1 purchase orders": "Passer 1 bon de commande",
  "With just one click, you can easily allocate your stores orders to your vendors and the entire shipping process will be automatically completed.": "En un seul clic, vous pourrez facilement passer vos commandes en magasin pour vos fournisseurs et l'ensemble du processus d'expédition sera automatiquement complété.",
  "Create a purchase orders to your vendor.": "Créez des bons de commande pour votre fournisseur.",
  "Choose the Shopify orders you want to sourcing,and click the Make a purchase order button of the page.": "Choisissez les commandes Shopify que vous souhaitez recevoir et cliquez sur le bouton Passer une commande d'achat sur la page.",
  "After confirming the operation,The purchase orders will be created successfully.": "Après avoir confirmé l'opération, les bons de commande seront créés avec succès.",
  "Share purchase orders URL to your vendor and wait your vendor finish the invoice for you.": "Partagez l'URL du bon de commande avec votre fournisseur et attendez que le vendeur complète la facture pour vous.",
  "Click the <b>Invite vendor to process</b> button on the bottom of the page.": "Cliquez sur le bouton <b>Inviter le fournisseur à traiter</b> en bas de la page.",
  "You can send the purchase orders by Skype, WeChat or share by URL link.": "Vous pouvez soumettre des bons de commande via Skype, WeChat ou les partager via un lien URL.",
  "I have finished the payment": "j'ai fini le paiement",
  "<b>Invite vendor to process</b>": "<b>Inviter le fournisseur à traiter</b>",
  "Items total": "Articles au total",
  "You can create a new vendor or select an exist one.": "Vous pouvez créer un nouveau fournisseur ou en sélectionner un existant.",
  "Amount Due": "Montant dû",
  "Payment method": "Moyen de paiement",
  "Click the button": "Cliquez sur le bouton",
  "on the PO page when you finished your payment.": "sur la page du bon de commande lorsque vous avez terminé votre paiement.",
  "Through AliExpress proxy payment link": "Via le lien de paiement proxy Aliexpress",
  "Invite vendor to proess purchase order": "Après réception du fournisseurInviter le fournisseur à traiter le bon de commande",
  "After you receive the vendor": "Après réception du fournisseur",
  "Click the button <b>I have finished the payment</b> on the PO page when you finished your payment.": "Cliquez sur le bouton <b>J'ai effectué le paiement</b> sur la page du bon de commande lorsque vous avez effectué votre paiement.",
  "After vendor shipped, Shopify order will be fulfilled automatically.": "Une fois expédiée par le fournisseur, votre commande Shopify sera traitée automatiquement.",
  "After your vendor shipped your order, the shipping information will be sync to your Shopify store automatically.": "Une fois que votre fournisseur a expédié votre commande, vos informations d'expédition seront automatiquement synchronisées avec votre boutique Shopify.",
  "Billing History": "Historique de facturation",
  "Billing": "porter",
  "Quotation": "Rendez-vous",
  "Search by bill number or order number": "Recherche par numéro de compte ou numéro de commande",
  "Order Code": "Code de commande",
  "Payment Method": "Moyen de paiement",
  "Payment": "Payer",
  "Request for Quote": "RFQ",
  "Wait for import": "Attendre l'importation",
  "My Profile": "Mon profil",
  "Allocate Orders for vendors": "Attribuer les commandes aux fournisseurs",
  "and Automate <br />your entire dropshipping process": "et automatisez l'ensemble de votre processus de dropshipping",
  "With just one click, you can easily allocate your stores orders to": "En un seul clic, vous pouvez facilement attribuer vos commandes en magasin à",
  "your vendors and the entire shipping process will be automatically": "vos fournisseurs et l'ensemble du processus d'expédition seront automatiquement",
  "completed.": "fini.",
  "How does it work?": "Comment ça marche?",
  "Connect to your store": "Connectez-vous à votre boutique",
  "Specifications": "Caractéristiques",
  "Allocate orders to your vendors": "Attribuez des commandes à vos fournisseurs",
  "The vendor arranges the delivery": "Le fournisseur organise la livraison.",
  "Automatic fulfillment of your orders by": "Exécution automatique de vos commandes via",
  "See what our": "Regardez ce que notre",
  "customers talk about us.": "Les clients parlent de nous.",
  "More than 10,000+ customers around the world rely on us!": "Plus de 10 000 clients dans le monde nous font confiance !",
  "Import List": "Liste d'importation",
  "Order": "Commande",
  "about": "à propos de",
  "Inquiry": "Consultation",
  "Waiting": "attendre à",
  "Trial calculation of shipping costs": "Testez le calcul des frais de port.",
  "Return able with in": "Retour capable avec sur",
  "Push Setting": "Paramètres de poussée",
  "You can import and manage your AliExpress / Amazon products here": "Vous pouvez importer et gérer vos produits Aliexpress/Amazon ici",
  "before publishing them to your Shopify store. You can publish a": "avant de les publier sur votre boutique Shopify. Tu peux",
  "product to different stores and add multiple tags to each products.": "produit pour différents magasins et ajoutez plusieurs balises pour chaque produit.",
  "The Shopify orders still unfulfilled / partially fulfilled even if the vendor had upload tracking information.": "Les commandes Shopify ne sont toujours pas exécutées ou partiellement exécutées même si le fournisseur a téléchargé des informations de suivi.",
  "You may download these tracking information and fulfilled these order in your Shopify store manually.": "Vous pouvez télécharger ces informations de suivi et exécuter ces commandes manuellement dans votre boutique Shopify.",
  "Click": "Cliquer",
  "New Type": "Nouveau genre",
  "need your reconfirm,": "a besoin de votre reconfirmation",
  "here": "ici",
  "Operate": "fonction",
  "Pending": "boucle d'oreille",
  "Approved": "Approuvé",
  "to leam more": "Apprendre encore plus",
  "Import Product": "produit d'importation",
  "Current Import Task": "Tâche d'importation en cours",
  "Import Task": "Tâche d'importation",
  "Add Tags": "ajouter des balises",
  "Tag management": "Gestion des balises",
  "Filter by stores": "Filtrer par magasins",
  "Upload Progress:": "Progression du chargement:",
  "Filter by tags": "Filtrer par balises",
  "Select an image": "Sélectionnez une image",
  "Add Cost Range": "Ajouter une fourchette de coûts",
  "Rest of the price ranges": "Reste des gammes de prix",
  "You can set a specific cent value for your price or comparison": "Vous pouvez définir une valeur en centimes spécifique pour votre prix ou votre comparaison",
  "price.": "prix",
  "Assign Cent": "Attribuer un centime",
  "According to store currency": "En fonction de la devise du magasin.",
  "Selected products": "Produits sélectionnés",
  "Also publish to Online Store": "Publiez également dans la boutique en ligne.",
  "Loading time to long, check detailed progress": "Temps de chargement trop long, vérifiez la progression détaillée",
  "Do you want to delete this task?": "Voulez-vous supprimer cette tâche?",
  "Delete Completed": "Suppression terminée",
  "Please select the stores": "Veuillez sélectionner des magasins",
  "Please enter the tag": "Veuillez entrer le tag",
  "Please enter the New tag": "Veuillez insérer l'étiquette Nouveau",
  "The update has started, it will take a while to finish.": "La mise à jour a commencé, elle prendra un certain temps pour se terminer.",
  "Do you want to delete the product(s)?": "Voulez-vous supprimer le(s) produit(s)?",
  "Import success": "Importation réussie",
  "Please enter your store name to connect the dashboard page:": "Entrez le nom de votre magasin pour créer un lien vers la page du tableau de bord:",
  "Coming soon": "Bientôt",
  "Track Number": "Code de suivi",
  "Shopify order number": "Numéro de commande Shopify",
  "Shopify store Name": "Nom du magasin Shopify",
  "Products Market": "Marché de produits",
  "Qty Available": "Quantité disponible",
  "Keywords": "Mots-clés",
  "First Available": "Premier disponible",
  "Sort by": "Trier par",
  "results": "résultats",
  "Last 14 days": "14 derniers jours",
  "Please enter your shopify store name to connect the dashboard page": "Entrez le nom de votre boutique Shopify pour connecter la page du tableau de bord",
  "Assign orders to vender": "Attribuer des ordres de vente",
  "Step One: Select not shipped orders": "Première étape: sélectionner les commandes non expédiées",
  "Step Two: Confirm and send these orders to the vendor": "Deuxième étape : Confirmez et envoyez ces commandes au fournisseur.",
  "Choose one vendor": "Choisissez un fournisseur",
  "Please choose one vendor first": "Veuillez d'abord choisir un fournisseur",
  "All Stores": "Tous les magasins",
  "Abort to assign": "Annuler pour attribuer",
  "Go to Next": "Aller au suivant",
  "Go": "Aller",
  "Confirm and send these orders to this vendor": "Confirmer et envoyer ces commandes à ce fournisseur",
  "You can check the invoice by scan this QR Code or visit this URL by your web browser": "Vous pouvez vérifier la facture en scannant ce code QR ou visiter cette URL via votre navigateur Web",
  "Time Created": "Temps de creation",
  "Receiver": "Destinataire",
  "Company": "Entreprise",
  "Save and change the vendor": "Enregistrer et changer de fournisseur",
  "Selected saved": "Sélectionné enregistré",
  "Now you can": "maintenant vous pouvez",
  "Send purchase orders to the vendor": "Envoyer les bons de commande au fournisseur.",
  "Check the list again": "Vérifiez à nouveau la liste",
  "Send purchase orders to the vendor": "Envoyer les bons de commande au fournisseur.",
  "A new order successfully created": "Une nouvelle commande créée avec succès",
  "All Unfulfilled Shopify Orders": "Toutes les commandes Shopify non exécutées",
  "Quick select of Shopify order items": "Sélection rapide des articles de commande Shopify",
  "Shopify order total": "Total des commandes Shopify",
  "Order Date": "Date de solicitude",
  "Periods of orders:": "Périodes de commande :",
  "Quick select of Shopify order items": "Sélection rapide des articles de commande Shopify",
  "shopify orders have been selected and can now be dispatched to your vendor": "Les commandes Shopify ont été sélectionnées et peuvent désormais être envoyées à votre fournisseur",
  "Dispatch to the vendor": "Expédition au fournisseur",
  "Please choose an vendor": "Veuillez choisir un fournisseur",
  "Please choose an item": "Veuillez choisir un article",
  "A new order successfully created": "Une nouvelle commande créée avec succès",
  "All shopify unshipped orders": "Toutes les commandes Shopify non expédiées",
  "All undelivered shopify orders": "Toutes les commandes Shopify non livrées",
  "shopify orders have been selected and can now be assigned to your suppliers": "Les commandes Shopify ont été sélectionnées et peuvent désormais être attribuées à vos fournisseurs.",
  "customers successfully added": "les clients ont ajouté avec succès",
  "Payment Record": "Dossier de paiement",
  "Recharge": "Recharger",
  "Payment Record": "Dossier de paiement",
  "trade code": "Code de commerce",
  "Payment Amount": "paiement du montant",
  "Payment Status": "Statut de paiement",
  "Payment Number": "Numéro de paiement",
  "Payment Date": "Jour de paie",
  "You have authorized a Shopify store，you could": "Vous avez autorisé une boutique Shopify qui pourrait",
  "authorize to login": "autoriser la connexion",
  "to view details。": "pour voir les détails",
  "If it is necessary to process your shopify order or to achieve an automatically update for the shipping information toshopify, you could": "Si vous devez traiter votre commande Shopify ou obtenir une mise à jour automatique de vos informations d'expédition Shopify, vous pouvez",
  "connect your Shopify store": "connectez votre boutique Shopify",
  "and let us help you.": "et laissez-nous vous aider.",
  "If you have authorized a Shopify store, after the vendor delivers the items, the shipping information will been synchronized to your Shopify store which you had authorized.": "Si vous avez autorisé une boutique Shopify, une fois que le fournisseur a livré les articles, les informations d'expédition seront synchronisées avec la boutique Shopify que vous avez autorisée.",
  "(If there are some Shopify stores in the order list below that have not been authorized, you can": "(S'il y a des magasins Shopify dans la liste de commande ci-dessous qui n'ont pas été autorisés, vous pouvez",
  "authorize a new one": "autoriser un nouveau",
  "If you need to view your recent orders, please click": "Si vous avez besoin de consulter vos commandes récentes, cliquez sur",
  "My to view details.": "Pour voir les détails.",
  "Cancelled": "annulé",
  "Purchase Order": "Bon de commande",
  " The vendor has shipped the product(s), but the shipping information has not been sync to your Shopify store.": "Le fournisseur a expédié les produits, mais les informations d'expédition n'ont pas été synchronisées avec votre boutique Shopify.",
  "Will not ship": "N'enverra pas",
  "Not shipped": "Pas envoyé",
  "Quantity": "montant",
  "Subtotal": "Total",
  "Logistics fee": "Frais de logistique",
  "The webmaster has confirmed the  payment": "Le webmaster a confirmé le paiement.",
  "The vendor has confirmed the payment": "Le vendeur a confirmé le paiement.",
  "The vendor start delivery the orders": "Le fournisseur commence à livrer les commandes.",
  "Shipping costs": "Les frais de livraison",
  "Discount": "Rabais",
  "Amount Due": "Montant dû",
  "Payment method": "Moyen de paiement",
  "Via paypal": "Via Paypal",
  "Through AliExpress proxy payment link": "Via le lien de paiement proxy Aliexpress",
  "Through a bank transfer": "Par virement bancaire",
  "According to the vendor’s setting you can go to": "En fonction des paramètres de votre fournisseur, vous pouvez accéder à",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress pour acheter l'article dans le magasin du fournisseur.",
  "Go to AliExpress NOW": "Allez sur Aliexpress MAINTENANT",
  "Invite vendor to process purchase order": "Invitez le fournisseur à traiter le bon de commande.",
  "webmaster has confirmed the payment": "Le vendeur a confirmé le paiement.",
  "The vendor has confirmed the payment": "Le fournisseur commence à livrer les commandes.",
  "The vendor start delivery the orders": "Le fournisseur commence à livrer les commandes.",
  "The webmaster has confirmed the payment": "Le webmaster a confirmé le paiement.",
  "The vendor has confirmed the payment": "Le vendeur a confirmé le paiement.",
  "The vendor start delivery the orders": "Le fournisseur commence à livrer les commandes.",
  "According to the vendor’s setting you can go to": "En fonction des paramètres de votre fournisseur, vous pouvez accéder à",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress pour acheter l'article dans le magasin du fournisseur.",
  "Go to AliExpress NOW": "Allez sur Aliexpress MAINTENANT",
  "Invite vendor": "Inviter un fournisseur",
  "You can download order": "Vous pouvez télécharger la commande",
  "Timeline": "Ligne de temps",
  "No more comment": "Pas d'autres commentaires",
  "You have connected a Shopify store before, for your data security, please": "Avez-vous déjà connecté une boutique Shopify ? Pour la sécurité de vos données, s'il vous plaît.",
  "log in the Shopify account": "Connectez-vous à votre compte Shopify",
  "you connected and view this invoice": "vous vous êtes connecté et avez vu cette facture",
  "The current invoice page is for preview purposes only, if you are sending to a customer please use the": "La page de facture actuelle est uniquement à des fins d'affichage; Si vous l'envoyez à un client, utilisez le",
  "Invoice Preview": "Aperçu de la facture",
  "As shown in Figure 1:": "Comme indiqué dans la figure 1:",
  "As shown in Figure 2:": "Comme indiqué sur la figure 2:",
  "I knew it": "Je savais",
  "Connect your Shopify store": "Connectez votre boutique Shopify",
  "you can dispatch the Shopify orders to your vendors after the authorization and the shipping information will be automatically synchronized to your Shopify Store.": "Vous pouvez expédier les commandes Shopify à vos fournisseurs après autorisation et les informations d'expédition seront automatiquement synchronisées avec votre boutique Shopify.",
  "Enter your existing Shopify URL bellow and click “connect”, you will be redirect to Shopify to connect your account to (the authorization will be also completed).": "Entrez votre URL Shopify existante ci-dessous et cliquez sur « se connecter », vous serez redirigé vers Shopify pour connecter votre compte (l'autorisation sera également complétée).",
  "Connect your Shopify store": "Connectez votre boutique Shopify",
  "Connect a store and fulfill your Shopify orders, sync logistics information to your Shopify store automatically.": "Connectez une boutique et exécutez vos commandes Shopify, synchronisez automatiquement les informations logistiques avec votre boutique Shopify.",
  "Enter your existing Shopify store URL bellow and you": "Entrez l'URL de votre boutique Shopify existante ci-dessous et",
  "Purchase order base information": "Informations de base du bon de commande",
  "Total number of orders": "Nombre total de commandes",
  "Order items": "Commander des articles",
  "Total goods": "Actif total",
  "Total purchase amount": "Valeur totale d'achat",
  "Shipment statistics": "Statistiques sur les envois de fonds",
  "Number of items shipped": "Nombre d'articles expédiés",
  "Amount of goods shipped": "Quantité de marchandises expédiées",
  "Number of items not shipped": "Nombre d'articles non expédiés",
  "Amount of unshipped goods": "Quantité de marchandises déchargées",
  "Shipment rate": "Frais d'envoi",
  "Number of items not shipped": "Nombre d'articles non expédiés",
  "Amount of non-delivery items": "Nombre d'articles non livrés",
  "Set the vendor’s SKU": "Définir le SKU du fournisseur",
  "Set the supply price": "Fixer le prix de fourniture",
  "Set up payment methods": "Configurer les modes de paiement",
  "Payment Methods": "Moyen de paiement",
  "Send the invoice to the customer": "Envoyez la facture au client.",
  "The fields to be exported": "Les champs à exporter",
  "the sequence is adjustable by dragging forward and backward": "La séquence est réglable en faisant glisser d'avant en arrière",
  "You have not yet selected the fields that can be exported. Please select below.": "Vous n'avez pas encore sélectionné les champs pouvant être exportés. Veuillez sélectionner ci-dessous.",
  "optional fields": "champs facultatifs",
  "unexported fields": "champs non résolus",
  "custom field name": "nom du champ personnalisé",
  "is displayed as": "Il est montré comme",
  "Export order": "Commande d'exportation",
  "BILL To": "FACTURER À",
  "Invoice Number": "Numero de facture",
  "Invoice Date": "Date de facture",
  "Amount Due (US$)": "Montant dû",
  "The supplier has shipped, but the logistics information has not been synced to the Shopify shop.": "Le fournisseur a effectué l'expédition, mais le je",
  "Reason": "Raison",
  "The supplier has shipped and the logistics information has been synced to the Shopify shop.": "Le fournisseur a effectué l'expédition et les informations logistiques ont été synchronisées avec la boutique Shopify.",
  "Mark as shipped": "Marquer comme envoyé",
  "The webmaster has confirmed the payment": "Le webmaster a confirmé le paiement.",
  "The vendor has confirmed the payment": "Le vendeur a confirmé le paiement.",
  "According to the vendor’s setting you can go to": "En fonction des paramètres de votre fournisseur, vous pouvez accéder à",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress pour acheter l'article dans le magasin du fournisseur.",
  "to finish the payment": "pour finaliser le paiement",
  "Go to AliExpress NOW": "Allez sur Aliexpress MAINTENANT",
  "The vender has confirmed the payment.": "Le vendeur a confirmé le paiement.",
  "Remove from order": "Supprimer de la commande",
  "You are deleting this product from purchase order. You can still find it": "Vous supprimez ce produit du bon de commande. tu peux toujours le trouver",
  "in your": "en toi",
  "Total Amount:": "Montant total:",
  "Pay with": "Payer avec",
  "Your order has been successfully paid and the order amount is": "Votre commande a été payée avec succès et le montant de la commande est",
  "Close Pay": "Fermer le salaire",
  "Remove success": "Supprimer le succès",
  "Track number must be entered": "Le code de suivi doit être saisi.",
  "Track company must be entered": "L'entreprise de sentiers doit être enregistrée.",
  "Track company must be entered": "L'entreprise de sentiers doit être enregistrée.",
  "Track Url must be entered": "L'URL de la piste doit être saisie",
  "Please enter Shopify store URL": "Saisissez l'URL de votre boutique Shopify",
  "Under authorization, please wait…": "Sous autorisation, veuillez patienter...",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "Après confirmation du paiement, nous informerons le fournisseur par email. Vous pouvez également informer le fournisseur de manière proactive pour accélérer la livraison.",
  "Have you finished the payment": "Vous avez terminé le paiement",
  "At least one field must be selected": "Au moins un champ doit être sélectionné",
  "The name of the customized header cannot be empty": "Le nom de l'en-tête personnalisé ne peut pas être vide",
  "Export the order information": "Exporter les informations de la commande",
  "The size of the uploaded image should not exceed 500kb!": "La taille de l'image téléchargée ne doit pas dépasser 500 Ko !",
  "Simple, Transparent Pricing": "Une tarification simple et transparente",
  "mportify offers three types of plans; Basic plan, Premium plan, and Gold plan.": "mportify propose trois types de forfaits : le forfait de base, le forfait Premium et le forfait Gold.",
  "Each account starts with a day trial, and your billing cycle begins after the free trial period has ended.": "Chaque compte commence par un essai d'une journée et votre cycle de facturation commence après la fin de la période d'essai gratuite.",
  "Importify Plans<": "Plans d'importation",
  "Basics": "Basique",
  "Premium": "Prime",
  "Gold": "D'or",
  "Offers": "Des offres",
  "Amazon & Aliexpress importer": "Importateur Amazon et Aliexpress",
  "Semi-automatic order fulfillment (Supported on Aliexpress, Amazon, Dhgate & Wonify)": "Traitement des commandes semi-automatique (prend en charge Aliexpress, Amazon, Dhgate et Wonify)",
  "Product Customisation": "Personnalisation de produit",
  "Description text": "Texte descriptif",
  "The tax-inclusive price of an item is calculated based on the item price set by the seller and the applicable tax rate. You can contact the seller for more details.": "Le prix d'un article TTC est calculé en fonction du prix indiqué par le vendeur pour l'article et du taux de taxe applicable. Vous pouvez contacter le vendeur pour plus de détails.",
  "VAT rate(Destination only in Germany)": "Taux de TVA (destination Allemagne uniquement)",
  "Shipping options": "Les options d'expédition",
  "Fulfilment costs": "Coûts de conformité",
  "Fulfillment Costs": "Coûts de conformité",
  "Shipping time": "temps d'expédition",
  "Update Product": "Mettre à jour le produit",
  "Shipping Time": "temps d'expédition",
  "All Action": "Toute l'action",
  "After Sales Service": "Service après vente",
  "product description": "description du produit",
  "Download the attachment": "Téléchargez le fichier ci-joint",
  "product information": "Information produit",
  "Product category": "catégorie de produit",
  "Specification": "spécification",
  "material": "matériel",
  "Store price": "Prix ​​magasin",
  "Delivery: Varies for items shipped from an international location": "Livraison:varie pour les articles expédiés depuis un emplacement international.",
  "This product has been removed from the shelves": "Ce produit a été retiré des rayons.",
  "Return": "Retour",
  "Enter Store name": "Entrez le nom du magasin",
  "Enter vendor name": "Entrez le nom du fournisseur",
  "Open in website": "Ouvert sur le site Internet",
  "Retail price:": "Prix ​​en détail:",
  "NOT SET": "NON DÉFINI",
  "Supplier price": "Prix ​​fournisseur",
  "GENERAL INFORMATION": "INFORMATIONS GÉNÉRALES",
  "CONTACT": "CONTACT",
  "SOCIAL": "SOCIALE",
  "More": "Plus loin",
  "Store Name": "Nom du magasin",
  "Notification Settings": "Paramètres de notification",
  "I want to receive notfications by": "Je souhaite recevoir des notifications par",
  "Mobile phone": "Téléphone portable",
  "Email": "Courrier électronique",
  "Allow system notifhcations": "Autoriser les notions système",
  "Allow Customer Services from": "Activer le service client",
  "Allow Dropshipping News": "Autoriser les actualités dropshipping",
  "Save Changes": "Enregistrer les modifications",
  "Reset All": "tout réinitialiser",
  "Purchase List": "Liste d'achats",
  "Complete your Skype or WhatsApp details to get rewards": "Remplissez vos détails Skype ou WhatsApp pour obtenir des récompenses",
  "You have completed the Skype or WhatsApp, you can receive a free gift package.": "Vous avez complété Skype ou WhatsApp, vous pouvez recevoir un coffret cadeau gratuit.",
  "The captcha was sent successfully": "Le captcha a été envoyé avec succès",
  "Order Details": "Détails de la commande",
  "Sub Order NO": "Sous-ordre NON",
  "Sold Out": "Vendu",
  "Status": "État",
  "Images": "images",
  "Product Information": "Information produit.",
  "Unit Price": "prix unitaire",
  "Quantity": "montant",
  "Sub Total": "Total",
  "Grand Total": "Total",
  "Address": "ADRESSE",
  "Tracking Number:": "Numéro de suivi:",
  "Total Amount": "Montant total",
  "Products Cost": "Coût des produits",
  "Cost": "Coûter",
  "Weight": "Poids",
  "Shipping": "Transport maritime",
  "Enter SKU": "Entrez le SKU",
  "Package": "Emballer",
  "I agree the": "je suis d'accord avec lui",
  "Pay NOW": "Payer maintenant",
  "All vendors": "Tous les fournisseurs",
  "Dispatch Status": "Statut d'envoi",
  "You could select multiple vendors": "Vous pouvez sélectionner plusieurs fournisseurs",
  "Shipping Cost Included": "Frais de transport inclus",
  "Do you want to cancel the order?": "Voulez-vous annuler la commande?",
  "Order was successfully cancelled": "La commande a été annulée avec succès",
  "Choose your recharge package": "Choisissez votre forfait de recharge",
  "Choose your payment method": "Choisissez votre méthode de paiement",
  "Payment Record": "Dossier de paiement",
  "Terms of use": "Conditions d'utilisation",
  "Refund Policy": "Politique de remboursement",
  "History": "Histoire",
  "Platform Reply": "Réponse de la plateforme",
  "Picture": "Cadre",
  "You can only upload 5 pictures": "Vous ne pouvez envoyer que 5 photos.",
  "Product Title:": "Titre du produit:",
  "Price Range:": "Échelle des prix:",
  "Base Information": "Information de base",
  "Product Links:": "lien produit",
  "Product Description:": "Description du produit:",
  "Publish": "publier",
  "Trade Name": "Nom commercial",
  "Only matching last": "Cela ne correspond qu'au dernier",
  "Save Rule": "Enregistrer la règle",
  "Please select the Period": "Veuillez sélectionner la période",
  "Please select the vendor visibility": "Sélectionnez la visibilité du fournisseur",
  "Automatic dispatch orders setting": "Configurer les commandes d'expédition automatique",
  "Show hidden orders": "Afficher les commandes masquées",
  "Choose my all Shopify products": "Choisir tous mes produits Shopify",
  "shopify orders have been selected and can now be dispatched to your vendors": "Les commandes Shopify ont été sélectionnées et peuvent désormais être envoyées à vos fournisseurs",
  "Dispatch to the vendor": "Expédition au fournisseur",
  "My all Shopify products": "Tous mes produits Shopify",
  "days unfulfilled orders": "jours de commandes non exécutées",
  "Dispatch rule TEST": "Règle de soumission TEST",
  "According to your settings, we found some data for you:": "En fonction de votre configuration, nous trouvons quelques données pour vous:",
  "THE BRIDGE CONNECTING SUPPLIERS AND YOU, MAKING DROPSHIPPING BUSINESS MORE CONVENIENT": "LE PONT QUI RELIENT LES FOURNISSEURS ET VOUS, RENDANT LE COMMERCE DE DROPSHIPPING PLUS PRATIQUE",
  "Your supplier only needs to register an account to quickly build the system": "Votre fournisseur n'a qu'à créer un compte pour construire rapidement le système",
  "One-click hosting of stores, fast synchronization of orders": "Hébergement de magasin en un clic, synchronisation rapide des commandes",
  "Automatically synchronize logistics information": "Synchronisez automatiquement les informations logistiques",
  "Quick Access": "Accès rapide",
  "Do you have an BCN supplier for you": "Avez-vous un fournisseur BCN pour vous ?",
  "Already have a supplier": "Vous avez déjà un fournisseur",
  "I don": "Moi non",
  "Let your supplier fulfill your order more systematically, copy the link and send it to your supplier, and immediately invite your supplier to settle in,": "Laissez votre fournisseur exécuter votre commande plus systématiquement, copiez le lien et envoyez-le à votre fournisseur, et invitez-le immédiatement à s'installer,",
  "2024-2024BCN All Rights Reserved": "2024-2024BCN Tous droits réservés",
  "Waiting for the payment result to be returned": "En attente du retour du résultat du paiement",
  "You have successfully completed your order": "Vous avez complété avec succès votre commande",
  "Payment failed": "Échec du paiement",
  "Product Details": "détails du produit",
  "Please check the purchase products": "Veuillez vérifier les produits achetés.",
  "out of stock": "vendu",
  "Bonus": "Prime",
  "Quality": "Qualité",
  "Unable to be sourced": "Je n'arrive pas à l'obtenir",
  "Combination payment": "Paiements combinés",
  "Please enter the balance to be used": "Veuillez saisir le solde à utiliser",
  "Rules: The input amount should be less than the order amount, and only two decimal places": "Règles : L'acompte doit être inférieur au montant de la commande et à seulement deux décimales.",
  "After the combination payment is enabled, the account balance will be deducted first, and the rest will be paid by other selected methods": "",
  "Amount due (including freight)": "Montant dû (comprend les frais d'expédition)",
  "Please select the goods first!": "Veuillez d'abord sélectionner les produits!",
  "Please choose the logistics channel": "Veuillez choisir le canal logistique",
  "Please try again later ~": "Réessayez plus tard",
  "Wholesale order": "Commande en gros",
  "offline order": "commande hors ligne",
  "Shipping Address": "Adresse de livraison",
  "User Name": "Nom d'utilisateur",
  "Users login": "Utilisateur en ligne",
  "Password": "Mot de passe",
  "Add a new store": "ajouter un nouveau magasin",
  "Auth Status": "statut d'authentification",
  "All Status": "tout l'état",
  "Unauthorized": "Pas autorisé",
  "Creation date": "date de creation",
  "Auth date:": "Date d'authentification:",
  "Delete Task": "Supprimer la tâche",
  "Sale price": "Prix ​​de vente",
  "Select the task": "Sélectionnez la tâche",
  "Create a ticket": "Créer un billet",
  "Request": "Commande",
  "Store name:": "Nom du magasin",
  "No Message": "Pas de message",
  "Send": "Envoyer",
  "Please enter a value greater than 0": "Entrez une valeur supérieure à 0",
  "Please enter a value less than the total amount of the order": "Entrez une valeur inférieure à la valeur totale de la commande",
  "Tell us more about your request": "Dites-nous en plus sur votre commande",
  "What is your Shopify store name?": "Quel est le nom de votre boutique Shopify ?",
  "Transaction": "Transaction",
  "normal bill": "facture régulière",
  "Account payee": "bénéficiaire du compte",
  "Payment code": "code de paiement",
  "Third Party Number": "Nombre de tiers",
  "The export of time": "L'exportation du temps",
  "Petitioner：": "Pétitionnaire",
  "Trade type": "type de négociation",
  "Rewards QTY left": "Récompenses du montant restant",
  "Trade amount": "Valeur commerciale",
  "Payment type": "Mode de paiement",
  "Amount paid/Credit payment": "Montant payé/paiement de crédit",
  "Payment order": "Ordre de paiement",
  "Refund order": "Commande de remboursement",
  "Wallet card": "mallette",
  "Manual deduction": "déduction manuelle",
  "Paying": "Payer",
  "Account paid": "Compte de paiement",
  "Vender Name": "Vendre le nom",
  "AliExpress Shop Name": "Nom du magasin Aliexpress",
  "Product allocated": "Produit attribué",
  "Purchase amount:": "Montant des achats:",
  "Created at:": "Créé en:",
  "Sourcing Now": "Fournir maintenant",
  "Latest cooperation at:": "Dernière coopération sur :",
  "How to add": "Comment ajouter",
  "Email Security Verification": "Vérification de la sécurité des e-mails",
  "For security reason.We need to verify your email address.Please click": "Pour des raisons de sécurité. Nous devons vérifier votre adresse e-mail. Cliquez sur.",
  "Pay": "Payer",
  "Verify": "Vérifier",
  "My Wallet": "Mon portefeuille",
  "My Balance": "Mon solde",
  "brokerage": "courtage",
  "withdraw": "Retirer",
  "Please enter the withdrawal amount": "Veuillez saisir le montant du retrait",
  "tip": "Conseil",
  "Your Total Rewards": "Vos récompenses totales",
  "orders QTY": "nombre de commandes",
  "We Accept": "Nous acceptons",
  "Other details": "Autres détails",
  "Payment account": "Compte de paiement",
  "Payment voucher": "Reçu",
  "Sign up here": "Inscrivez-vous ici",
  "Forgot your password?": "Vous avez oublié votre mot de passe?",
  "Payment instructions：": "Instructions de paiement",
  "Charging Method": "Méthode de chargement",
  "Pay with Credit Card": "Payez avec une carte de crédit",
  "Paypal Payment Amount": "Montant du paiement",
  "The amount of each recharge must be more than 100 dollars.": "La valeur de chaque recharge doit être supérieure à 100 dollars.",
  "Please verify your email address here,": "Veuillez vérifier votre adresse e-mail ici,",
  "Withdraw Cash": "Pour retirer de l'argent",
  "I see": "Je vois",
  "Trade status": "situation des affaires",
  "Enter Verification Code": "Entrez le code de vérification",
  "Verify Email": "Vérifier le courriel",
  "Bill Number": "Numero de facture",
  "Transfer ID": "Identification du transfert",
  "wallet recharge": "recharger le portefeuille",
  "The minimum payment is $100.": "Le paiement minimum est de 100 $.",
  "Powered by": "nourris par",
  "Card Number": "Numéro de carte",
  "Expiration": "Expiration",
  "All transactions are secure and encrypted.": "Toutes les transactions sont sécurisées et cryptées.",
  "Account Balance": "Solde du compte",
  "Withdrawal amount": "Montant de retrait",
  "We will charge you 5% fee when withdrawing.": "Nous vous facturerons des frais de 5% lors du retrait.",
  "Send Verification Code": "Soumettre le code de vérification",
  "The verification code will be sent to your email": "Le code de vérification sera envoyé à votre adresse e-mail.",
  "Please enter your withdrawal amount": "Veuillez saisir le montant de votre retrait",
  "Please enter your account": "Veuillez entrer votre compte",
  "Please enter your account name": "Entrez le nom de votre compte",
  "Please enter the code": "Veuillez entrer le code",
  "Please enter the recharge amount": "Veuillez saisir le montant de la recharge",
  "The minimum payment is $100": "Le paiement minimum est de 100$",
  "Payment successful": "Paiement réussi",
  "The Code was sent successfully": "Le code a été envoyé avec succès.",
  "Our app is now updating the page you are visiting, we will auto refresh this page in 10 seconds.": "Notre application actualise maintenant la page que vous visitez; Nous actualiserons automatiquement cette page dans 10 secondes.",
  "I got it, please refresh it now": "Je l'ai déjà, mettez-le à jour maintenant.",
  "Estimated start time:": "Heure de début estimée:",
  "Security Verification": "Contrôle de sécurité",
  "We noticed you haven": "Nous avons remarqué que tu t'es réfugié",
  "For security reason.We need to verify your email address.": "Pour des raisons de sécurité. Nous devons vérifier votre adresse e-mail.",
  "Send To:": "Envoyer à:",
  "Please click": "Cliquez s'il vous plait",
  "Work order number": "Numéro de commande de service",
  "Pending review": "Examen en attente",
  "After-sales work order": "Bon de travail après-vente",
  "Work order type": "Type de commande de service",
  "order number": "Numéro de commande",
  "client": "client",
  "shop": "boutique",
  "creation time": "temps de creation",
  "Approval": "Approbation",
  "Transaction Type": "Type de transaction",
  "question type": "type de question",
  "Problem Description": "description du problème",
  "afterSales": "après vente",
  "Refund": "Remboursement",
  "Replacement": "Remplacement",
  "modify the related information for customer": "modifier les informations relatives au client",
  "cancel order": "pour annuler une commande",
  "No tracking information": "Il n'y a aucune information de suivi",
  "Tracking information not update too long time": "Les informations de suivi n'ont pas été mises à jour depuis longtemps.",
  "Item damage": "Dommages à l'objet",
  "Item missing": "Élément manquant",
  "Send wrong product": "Envoyer un mauvais produit",
  "Send wrong address": "Envoyer une mauvaise adresse",
  "On hold order": "En attente",
  "optional": "facultatif",
  "after sales": "après vente",
  "Please select an order first": "Veuillez d'abord sélectionner une commande",
  '请输入': 'Veuillez entrer',
  '工单号': 'Numéro de commande',
  '同意': 'accepter',
  "售后工单": "Commande de service après-vente",
  '工单类型': 'Type de commande',
  '客户名称': "Nom du client",
  '审批': "Approbation",
  "交易类型": "Type de transaction",
  "问题类型": "Type de problème",
  "问题描述": "Description du problème",
  '退款': "Remboursement",
  '更换': "Remplacement",
  "修改客户相关信息": "modifier les informations relatives au client",
  '取消订单': "Annuler la commande",
  '无跟踪信息': "Aucune information de suivi",
  '跟踪信息长时间未更新': "Les informations de suivi n'ont pas été mises à jour depuis trop longtemps",
  '物品损坏': "Article endommagé",
  '物品丢失': "Article manquant",
  '发送错误产品': "Envoyer le mauvais produit",
  '发送错误地址': "Envoyer à la mauvaise adresse",
  '订单暂停': "Commande en attente",
  '选填': "optionnel",
  "订单信息": "Informations de commande",
  "第三方订单编号": "Numéro de commande tiers",
  "订单编号": "Numéro de commande",
  "店铺": "Magasins",
  "创建时间": "Heure de création",
  "开泰银行支付": "Paiement par la Banque Kaieteur",
  "供应商已发货，但物流信息未同步到Shopify店铺。": "Le fournisseur a expédié, mais les informations logistiques n'ont pas été synchronisées avec la boutique Shopify.",
  "供应商已发货，物流信息已同步到Shopify店铺。": "Le fournisseur a expédié et les informations logistiques ont été synchronisées avec la boutique Shopify.",
  "您还没有选择可导出的字段。请在下方选择。": "Vous n'avez pas encore sélectionné les champs pouvant être exportés. Veuillez sélectionner ci-dessous.",
  "采购订单基础信息": "Informations de base de la commande d'achat",
  "订单总数": "Nombre total de commandes",
  "订单商品项": "Articles de commande",
  "合计商品总数": "Nombre total de produits",
  "合计采购金额": "Montant total de l'achat",
  "发货情况统计": "Statistiques d'expédition",
  "已发货商品数": "Nombre d'articles expédiés",
  "已发货商品额": "Montant des marchandises expédiées",
  "未发货商品数": "Nombre d'articles non expédiés",
  "未发货商品额": "Montant des marchandises non expédiées",
  "发货率": "Taux d'expédition",
  "不发货商品数": "Nombre d'articles non expédiés",
  "不发货商品额": "Montant des articles non livrés",
  "当前发票页面仅供预览，如果发送给客户请使用发票编辑页面的'发送发票'功能！": "La page actuelle de la facture est uniquement destinée à des fins de prévisualisation. Si vous l'envoyez à un client, veuillez utiliser la fonction 'Envoyer la facture' sur la page de modification de la facture.",
  "发票预览": "Aperçu de la facture",
  "如图一所示:页面顶部“发送发票”按钮": "Comme indiqué dans la Figure 1 : Bouton 'Envoyer la facture' en haut de la page",
  "如图二所示:页面底部'发送发票'按钮": "Comme indiqué dans la Figure 2 : Bouton 'Envoyer la facture' en bas de la page",
  "商品列表页": "Page de liste des produits",
  "底部": "Bas",
  "顶部": "Haut",
  "请输入单号范围": "Veuillez entrer la plage de numéros unique",
  "起始单号必须小于结束单号": "Le numéro de commande de départ doit être inférieur au numéro de commande final",
  "确定删除该商品？": "Voulez-vous vraiment supprimer cet article ?",
  "确定": "OK",
  "取消": "annuler",
  "已取消": "Annulé",
  "选取文件": "Sélectionner un fichier",
  "点击下载": "Cliquez pour télécharger",
  "订单导入模板": "Modèle d'importation de commande",
  "请按规范填写表格，仅支持xlsx格式文件": "Veuillez remplir le formulaire conformément aux spécifications, seuls les fichiers au format xlsx sont pris en charge",
  "上传的文件不能超过2M，只处理前99条数据": "Le fichier téléchargé ne peut pas dépasser 2M, et seules les 99 premières données sont traitées",
  '待审核': 'Non examiné',
  '通过': 'passer',
  '拒绝': 'rejeter',
  "余额":"solde",
  "线下":"hors ligne",
  '提现列表': "Liste de retraits",
  "提现方式":"Mode de retrait",
  "状态": "État d'approbation",
  '警告提示': "Conseils d'avertissement",
  '该分类下目前还没有商品': "Il n'y a actuellement aucun produit dans cette catégorie",
  '最大建议尺寸 200px * 200px, 图片可自适应': "Taille maximale recommandée 200px * 200px, l'image peut être adaptative",
  '添加图片': 'ajouter une image',
  '更改': 'changer',
  '删除': 'supprimer',
  '请联系供应商，完善站长外部编号': 'Veuillez contacter le fournisseur pour améliorer le numéro externe du webmaster',
  '返回': 'Retour',
  "上传图片大小不能超过 500kb!": "La taille de l'image téléchargée ne doit pas dépasser 500ko !",
  "描述文字": "Texte de description",
  "履行费用": "Coûts d'exécution",
  "发货时效": "Délai de livraison",
}