import Vue from 'vue' // 引入Vue
import VueI18n from 'vue-i18n' // 引入国际化的插件包

import elementPT from 'element-ui/lib/locale/lang/pt'
import elementES from 'element-ui/lib/locale/lang/es'
import elementFR from 'element-ui/lib/locale/lang/fr'
import elementEN from 'element-ui/lib/locale/lang/en'

import customEN from '@/lang/en';
import customPT from "@/lang/pt";
import customES from "@/lang/es";
import customFR from "@/lang/fr";

Vue.use(VueI18n) // 全局注册国际化包

export default new VueI18n({
  locale: localStorage.getItem('c_activeLanguage') || 'pt',
  messages: {
    en: {
      ...elementEN,
      ...customEN
    },
    pt: {
      ...elementPT,
      ...customPT
    },
    es: {
      ...elementES,
      ...customES
    },
    fr: {
      ...elementFR,
      ...customFR
    }
  }
})
